import React, { useState } from "react";
import ProcessForm from "./ProcessForm";
import URL from "../../../API/Api";
import Swal from "sweetalert2";

const AddProcess = ({ processNumberFlow,handleClose }) => {
  const [formData, setFormData] = useState({
    processName: "",
    orgName: "",
    department: "",
    processFlowOrder: "",
  });

  const [departments, setDepartments] = useState([]);

  console.log(processNumberFlow ,"bbnnn");


  //submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const processData = {
      process_name: formData.processName,
      org_id: formData.orgName,
      process_flow_order: processNumberFlow,
      dept_id: formData.department,
    };
    console.log("processData", processData);

    try {
      const response = await URL.post("api/process", processData);
      console.log("Process Data Successfully Submitted:", response.data);

      // Show success toaster
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Process Data Successfully Submitted",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#28a745",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Clear form data
      setFormData({
        processName: "",
        department: "",
        processFlowOrder: "",
      });

      // Optionally call handleClose to hide the form after successful submission
      handleClose();
    } catch (error) {
      console.error("Error submitting process data:", error);

      // Show error toaster
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error submitting process data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <ProcessForm 
        processNumberFlow={processNumberFlow}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        buttonName="Add Process"
        handleClose={handleClose}
        setDepartments={setDepartments}
      />
    </div>
  );
};

export default AddProcess;
