import React, { useEffect, useState } from "react";
import DeptButton from "./DeptButton";
import { FaBell } from "react-icons/fa";
import { FcBusinessman } from "react-icons/fc";
import Badge from "react-bootstrap/Badge";
import "./NavComponents.css";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { logoutUser } from "../../redux/AuthSlice";

const departments = [
  {
    dept_name: "TECH",
    hover_text: "Technology Department",
    path: "technology-department",
  },
  {
    dept_name: "OPS",
    hover_text: "Operations",
    path: "operations",
  },
  {
    dept_name: "SALES",
    hover_text: "Sales Department",
    path: "sales-department",
  },
  {
    dept_name: "PROJ",
    hover_text: "Projects Department",
    path: "projects-department",
  },
  {
    dept_name: "DVC",
    hover_text: "Deal Validation Committee",
    path: "deal-validation-committee",
  },
  {
    dept_name: "CST",
    hover_text: "Customer Support Team",
    path: "customer-support-team",
  },
  {
    dept_name: "ACC",
    hover_text: "Accounts Department",
    path: "accounts-department",
  },
  {
    dept_name: "HR",
    hover_text: "Human Resources",
    path: "human-resources",
  },
];

const Navbar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);


  const handleLogout = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!',
    });

    if (result.isConfirmed) {
      await dispatch(logoutUser());
      Swal.fire({
        title: 'Logged out!',
        text: 'You have been logged out successfully.',
        icon: 'success',
        timer: 2000, // Time in milliseconds before the alert closes (e.g., 2000 ms = 2 seconds)
        showConfirmButton: false, // Hide the OK button
    });
      navigate('/'); // Change to your desired route after logout
    }
  };

  return (
    <div className="header_container">
      {/* -- heading section -- */}
      <div >
       {isMobile ? 
       <h6>MIKRO CENTRAL</h6>
      :
      <h4>MIKRO CENTRAL</h4>
      } 
      </div>
      {/* -- department section -- */}
      <div className={ isMobile ? "navbar_departmentsMobile": "navbar_departments"}>
        {departments?.map((item) => (
          <DeptButton
            key={item?.dept_name}
            className="me-2"
            path={item?.path}
            name={item?.dept_name}
            hover_text={item?.hover_text}
          />
        ))}
      </div>
      {/* -- user and notification section -- */}
      <div className="nav-end d-flex align-items-center justify-content-end">
        <span className="bell position-relative me-3">
          <FaBell className="fs-2" size={isMobile ?20 :30} />
          <Badge pill bg="danger"  className="bell-badge position-absolute">
            9
          </Badge>
        </span>

         
       <Dropdown>
      <Dropdown.Toggle as="span" id="dropdown-basic" className="user d-flex flex-column align-items-center" style={{ cursor: 'pointer' }}>
        <FcBusinessman className="fs-1" />
        <p className="name m-0 fw-bold">Rajesh Rajanna</p>
        <h5 className="role m-0 fw-bold">ADMIN</h5>
      </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    
      </div>
    </div>
  );
};

export default Navbar;
