import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, OverlayTrigger, Table } from "react-bootstrap";
import Api, { API_URL } from "../../API/ServiceLayer";
import { Container } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import Select from "react-select";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import Swal from "sweetalert2";

const AddOrganizationStaff = () => {
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [listServiceDeps, setListServiceDeps] = useState([]);

  const [city, setCity] = useState();
  const [stateOptions, setStateOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [stateValue, setStatevalue] = useState([]);
  const [cityValue, setCityvalue] = useState([]);
  const [propertyValue, setPropertyValue] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [propertyId, setPropertyId] = useState();
  const [confirmToggle, setConfirmToggle] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [isPropertySelected, setIsPropertySelected] = useState(false);
  const [isPropertyListed, setIsPropertyListed] = useState(false);
  const [passwordIsTypePassword, setPasswordIsTypePassword] = useState(true);
  const [cPasswordIsTypePassword, setcPasswordIsTypePassword] = useState(true);
  const [toastObject, setToastObject] = useState({
    message: "",
    duration: 3000,
    variant: "",
  });

  //errors refs
  const [errors, setErrors] = useState({});
  const Departmentref = useRef();
  const DepartmentroleRef = useRef();
  const NameRef = useRef();
  const contactNumberRef = useRef();
  const emailRef = useRef();
  const AddressoneRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const postalcodeRef = useRef();
  const passwordRef = useRef();
  const confirmpasswrdRef = useRef();
  const compelpropRef = useRef();

  const [snackOpen, setSnackOpen] = useState(false);
  const [date, setDate] = useState();
  const [formData, setFormData] = useState({
    name: null,
    dob: "",
    mobile_number: "",
    email: "",
    address1: "",
    address2: "",
    country_id: 101,
    state_id: "",
    city_id: "",
    dep_id: "",
    dep_role_id: "",
    org_dept_id: "",
    org_dept_role_id: "",
    org_dept_data: [],

    isCatHead: 0,
    isCatLead: 0,
    postal_code: "",
    status: 1,
    password: "",
    password_confirmation: "",
    role: "Staff",
    property_id: propertyValue,
    is_create_ticket: 1,
    company_name: "",
  });

  // ******************* USEEFFECT ***************
  //set initial setUp
  useEffect(() => {
    let month = new Date().getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let dd = new Date().getDate();
    dd = dd < 10 ? "0" + dd : dd;
    let date = new Date().getFullYear() - 18 + "-" + month + "-" + dd;
    setDate(date);
    //get user id
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    setUserId(user?.id);
    //get country list
    Api.getData("user-management/api/v1/country?limit=300")
      .then((res) => res.json())
      .then((resultCountry) => {
        console.log("API-COUNTRY_LIST");
        setCountryList(resultCountry?.data);
        setLoader(true);
      })
      .catch((e) => {
        console.log("error", e);
        //setting loader
        setLoader(true);
      });

    //get department list
    Api.getData(`user-management/api/v1/orgdept`)
      .then((res) => res.json())
      .then((result) => {
        console.log("resswsw", result);
        if (result.status) {
          setListServiceDeps(
            result.data.filter((stat) => {
              if (stat.status !== 2) {
                return stat;
              }
            })
          );
        }
      });
  }, []);
  //OrganizationDepartments array for form input
  const serviceDep = listServiceDeps.map((i) => {
    return {
      id: i.id,
      name: i.department_name,
      code: i.department_code,
      dep_roles: i.organization_role_department,
    };
  });
  //OrganizationDepartmentRoles array for form input
  const depRoles =
    formData.dep_id && serviceDep.find((i) => i.id === Number(formData.dep_id));

  //creating array for DepTable based on dep_id and dep_role_id
  const AddDepartments = () => {
    const depExists = formData.org_dept_data.some(
      (dep) =>
        dep.dep_id === formData.dep_id &&
        dep.dep_role_id === formData.dep_role_id
    );

    if (depExists) {
      // Show error message
      setSnackOpen(true);
      setToastObject({
        message: "Department with this department role already exists",
        duration: 3000,
        variant: "warning",
      });
      return; // Stop further execution
    }
    const depName = serviceDep?.find(
      (i) => i.id === Number(formData.dep_id)
    ).name;
    console.log(depName, serviceDep, "name");
    const depRoleName = depRoles?.dep_roles?.find(
      (i) => i.id === Number(formData.dep_role_id)
    )?.dep_role_name;
    const depObj = {
      id: formData.org_dept_data.length + 1,
      depName: depName,
      dep_id: formData.dep_id,
      depRoleName: depRoleName,
      dep_role_id: formData.dep_role_id,
      is_default: formData.org_dept_data.length === 0 ? 1 : 0,
    };

    setFormData((prev) => ({
      ...prev,
      org_dept_data: [...prev.org_dept_data, depObj],
    }));
    setFormData((prev) => ({
      ...prev,
      dep_id: "",
      dep_role_id: "",
    }));
  };
  console.log(formData.org_dept_data, "depLisrt");

  //handle default status of departments and deproles
  const handleDefaultDeproleStatus = (rowData) => {
    setFormData((prev) => ({
      ...prev,
      org_dept_data: [
        ...formData.org_dept_data.map((i) => {
          if (i.id === rowData.id) {
            return {
              ...i,
              is_default: 1,
            };
          } else {
            return { ...i, is_default: 0 };
          }
        }),
      ],
    }));
  };

  //handle department and departmentrole delete from is default table



const handleDeleteDep = async (rowData) => {
  const message = rowData.is_default === 1
    ? "Are you sure you want to delete default data?"
    : "Are you sure you want to delete this data?";

  const result = await Swal.fire({
    title: 'Confirm to delete',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    backdrop: true,
    allowOutsideClick: false,
  });

  if (result.isConfirmed) {
    setFormData((prev) => ({
      ...prev,
      org_dept_data: prev.org_dept_data.filter((i) => i.id !== rowData.id),
    }));
    Swal.fire(
      'Deleted!',
      'Your data has been deleted.',
      'success'
    );
  }
};

  //check wheteher password and confirm passowrd is same
  const check_password = () => {
    if (formData.password != formData.password_confirmation) {
      setIsPasswordSame(false);
      return false;
    } else {
      setIsPasswordSame(true);
      return true;
    }
  };
  const check_propertyChoosed = () => {
    if (propertyValue.length > 0) {
      setIsPropertySelected(false);
      return true;
    } else {
      setIsPropertySelected(true);
      return false;
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Example validation rules
    if (!formData.name) {
      newErrors.name = "Username is required";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    if (!formData.mobile_number) {
      newErrors.mobile_number = "Enter Mobile Number";
    }

    if (
      formData.mobile_number.length > 0 &&
      formData.mobile_number.length !== 10
    ) {
      newErrors.mobile_number1 = "Enter 10 digits";
    }
    if (!formData.address1) {
      newErrors.address1 = "Enter Addresss";
    }
    if (!formData.country_id) {
      newErrors.country_id = "Choose country";
    }
    if (!formData.postal_code) {
      newErrors.postal_code = "Enter Postal code";
    }

    if (formData.postal_code.length > 0 && formData.postal_code.length !== 6) {
      newErrors.postal_code1 = "Enter 6 digits";
    }
    if (!formData.password) {
      newErrors.password = "Enter Password";
    }
    if (formData.length > 0 && formData.length < 8) {
      newErrors.password_correct = "Password atleast 8 digit";
    }
    if (formData.password.length < 8) {
      newErrors.password = "Min of 8 digits required";
    }
    if (formData.password_confirmation !== formData.password) {
      newErrors.password_confirmation = "Password Mismatch";
    }
    if (formData.org_dept_data.length < 1) {
      newErrors.org_dept_data = "Choose Atleast One Department";
    }
    // if (!formData.dep_role_id) {
    //   newErrors.dep_role_id = 'Choose Department Role';

    // } // Check if department is Operations and department role is Cluster Manager

    if (formData.org_dept_data.length > 0) {
      const isClusterManagerPresent = formData.org_dept_data.some(
        (item) => item.depRoleName.toLowerCase() === "cluster manager"
      );

      if (isClusterManagerPresent && propertyValue.length < 1) {
        newErrors.compulproperty = "Property is compulsory for Cluster Manager";
        // Cluster Manager is present in at least one object in the array
        console.log("present");
      } else {
        // Cluster Manager is not present in any object in the array
        console.log("not present");
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const form = event.currentTarget;
      console.log("myssss");
      //get local data
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      console.log("Thamizh", "local", user?.id);

      if (formData.org_dept_data.length <= 0) {
        setSnackOpen(true);
        setToastObject({
          message: "select atleast on department",
          duration: 3000,
          variant: "error",
        });
        return;
      }
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      }
      let isSame = check_password();
      let isPropertyChoosed = check_propertyChoosed();
      let emailVerifiedAt = new Date().toISOString();

      // to get onboard person role name
      let objectsWithStatusOne = user.roles.filter((obj) => obj.status === 1);
      let UseronboardString = objectsWithStatusOne.map((obj) => obj.role_name);
      const OnboardPerson =
        UseronboardString.length > 0 ? UseronboardString[0] : null;
      console.log(OnboardPerson, " <<<<<<<>>>>>>>>>>");

      let data = {
        name: formData?.name,
        mobile_no: formData?.mobile_number,
        email: formData?.email,
        password: formData?.password,
        address1: formData?.address1,
        cat_head: formData.isCatHead, // You can directly use formData.isCatHead
        cat_lead: formData.isCatLead,
        city_id: Number(formData?.city_id),
        state_id: Number(formData?.state_id),
        country_id: Number(formData?.country_id),
        postal_code: Number(formData?.postal_code),
        dob: formData?.dob,
        status: Number(formData.status),
        role: "Staff",
        password_confirmation: formData?.password_confirmation,
        contact_number: formData?.mobile_number,
        email_verified_at: emailVerifiedAt,
        created_by: userId,
        register_from: "Web",
        property_id: propertyValue ? propertyValue : " ",
        on_boarding_mode: OnboardPerson,

        org_dept_data: formData.org_dept_data.map((i) => {
          return {
            org_dept_id: Number(i.dep_id),
            org_dept_role_id: Number(i.dep_role_id),
            is_default: i.is_default,
          };
        }),

        is_create_ticket: 1,
      };
      if (formData?.address2) data["address2"] = formData?.address2;
      if (formData?.company_name) data["company_name"] = formData?.company_name;
      if (
        form.checkValidity() === true &&
        isSame === true
        // &&
        // isPropertyChoosed === true
      ) {
        Api.postMethod("user-management/api/v1/user", "post", data)
          .then((res) => res.json())
          .then((result) => {
            console.log("API-USER_CREATION", result);
            if (result.status == true) {
                Swal.fire({
                    title: '<strong>Success</strong>',
                    html: `<i>${result.message}</i>`,
                    icon: 'success',
                    timer: 5000,
                    showConfirmButton: false,
                    willClose: () => {
                      window.location.href = '/user-management/organization-staff';
                    },
                  });

              localStorage.setItem("message", this.state.message);
            } else {
                Swal.fire({
                    title: '<strong>Error</strong>',
                    html: `<i>${result.message}</i>`,
                    icon: 'error',
                    timer: 5000,
                    showConfirmButton: true,
                  }).then(() => {
                    // Optional: Additional actions can be performed here if needed
                  });
            }
          });
      }
    } else {
      if (errors.name) {
        NameRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.email) {
        emailRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.mobile_number) {
        contactNumberRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.mobile_number1) {
        contactNumberRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.org_dept_data) {
        Departmentref.current.scrollIntoView({ behavior: "smooth" });
      }
      // else if (errors.dep_role_id) {
      //   DepartmentroleRef.current.scrollIntoView({ behavior: 'smooth' });
      // }
      else if (errors.address1) {
        AddressoneRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.country_id) {
        countryRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.postal_code) {
        postalcodeRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.postal_code1) {
        postalcodeRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.password) {
        passwordRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.password_correct) {
        passwordRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.password_confirmation) {
        confirmpasswrdRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (errors.compulproperty) {
        compelpropRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  //call api when choose state
  useEffect(() => {
    if (formData.country_id) {
      Api.getData("user-management/api/v1/state/" + formData.country_id)
        .then((res) => res.json())
        .then((result) => {
          setStateList(result?.data);

          const validKeys = ["value", "label"];

          let statesData = [];
          const states = result?.data?.forEach((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;

            const {
              country_code,
              country_id,
              created_at,
              deleted_at,
              fips_code,
              flag,
              id,
              iso2,
              latitude,
              longitude,
              name,
              status,
              type,
              updated_at,
              wikiDataId,
              ...eleRest
            } = ele;
            statesData.push(eleRest);
          });

          setStateOptions(statesData);
        });
    }
  }, [formData.country_id]);
  //call api when choose state
  useEffect(() => {
    if (formData.state_id) {
      Api.getData("user-management/api/v1/city/" + formData.state_id)
        .then((res) => res.json())
        .then((result) => {
          setCity(result.data);
        });
    }
  }, [formData.state_id]);

  //handle when click clear button
 
const handleClear = (data) => {
    Swal.fire({
      title: 'Confirm',
      text: 'All data populated in the field will be discarded, do you wish to continue?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      backdrop: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/user-management/organization-staff';
      }
    });
  };

  const statusHandle = (e) => {
    let value = 0;
    e.target.checked == true ? (value = 1) : (value = 0);
    setFormData({ ...formData, status: value });
  };
  const AccessStatusHandle = (e) => {
    let value = 0;
    e.target.checked === true ? (value = 1) : (value = 0);
    console.log("e.target.checked", e.target.checked);
    setFormData({ ...formData, is_create_ticket: value });
  };

  const handlePropertyNames = (selectedProperties) => {
    console.log("selectedProperties", selectedProperties);
    const propertyChoose = selectedProperties?.map(
      (property) => property.value
    );
    const selectedPropertyId = selectedProperties
      ?.map((property) => property.value)
      .pop();
    // console.log("stateChoose", propertyChoose);
    // console.log("selectedPropertyId", selectedPropertyId);
    setPropertyValue(propertyChoose);
    setPropertyId(selectedPropertyId);
    console.log(propertyChoose, "property choose");
    console.log(selectedPropertyId, "selected ID");
    // console.log()
  };

  const handleCityNames = (selectedCities) => {
    console.log("selectedCities", selectedCities);
    const cityChoose = selectedCities?.map((city) => city.value);
    const selectedCityId = selectedCities?.map((city) => city.value).pop();
    console.log("cityChoose", cityChoose);
    console.log("selectedCityId", selectedCityId);
    setCityvalue(cityChoose);
    setCityId(selectedCityId);
  };

  const handleStatesName = (selectedStates) => {
    console.log("selectedStates", selectedStates);
    const stateChoose = selectedStates?.map((state) => state.value);
    const selectedStateId = selectedStates?.map((state) => state.value).pop();
    console.log("stateChoose", stateChoose);
    console.log("stateId", selectedStateId);

    setStatevalue(stateChoose);
    setStateId(selectedStateId);

    Api.getData("user-management/api/v1/city/" + selectedStateId)
      .then((res) => res.json())
      .then((result) => {
        let citiOptions = [];
        const citiesData = result?.data;
        if (citiesData?.length > 0) {
          console.log("laksh", "larger");
          citiesData.forEach((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
            ele.stateId = ele.state_id;
            const {
              country_code,
              country_id,
              state_id,
              state_code,
              created_at,
              deleted_at,
              fips_code,
              flag,
              id,
              iso2,
              latitude,
              longitude,
              name,
              status,
              type,
              updated_at,
              wikiDataId,
              ...eleRest
            } = ele;
            citiOptions.push(eleRest);
          });

          setCitiesOptions([...citiesOptions, ...citiOptions]);
        }
      });
  };

  const filteredCityOptions = Array.from(
    new Set(
      citiesOptions
        ?.filter(({ stateId }) => stateValue?.includes(stateId))
        ?.map(({ value }) => value)
    )
  )?.map((val) => citiesOptions?.find(({ value }) => value === val));

  const handleRadioChange = (event) => {
    const value = event.target.value;
    if (value === "cathead") {
      setFormData({
        ...formData,
        isCatHead: 1,
        isCatLead: 0,
      });
    } else if (value === "catlead") {
      setFormData({
        ...formData,
        isCatHead: 0,
        isCatLead: 1,
      });
    }
    console.log(value);
  };
  const handleLocation = () => {
    if (cityValue.length > 0) {
      setIsPropertyListed(true);
      Api.postMethod("user-management/api/v1/city-property", "post", {
        city_id: cityValue,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status) {
            setConfirmToggle((prevState) => true);
            let propertiesData = [];
            let data = result?.data?.forEach((ele) => {
              ele.label = ele.property_name;
              ele.value = ele.id;
              const {
                country_code,
                country_id,
                created_at,
                deleted_at,
                address1,
                address2,
                id,
                iso2,
                latitude,
                longitude,
                name,
                status,
                type,
                updated_at,
                wikiDataId,
                cctv_link,
                cgst,
                city_id,
                contact_alternate_email,
                contact_email,
                contact_no,
                contact_person,

                email,
                end_at,
                floor_file,
                floor_no,
                furnished_status,
                gst_registration_num,
                invoice_template_id,
                is_alternative_primary,
                is_cancel,
                is_cancellation_policy,
                is_draft,
                is_primary,
                is_qr_code,
                is_verified,
                landline,
                landmark,
                locality,
                long_desc,
                mobile_no,
                property_code,
                property_tabs,
                property_type_id,
                provider_id,
                pseudoname,
                qr_code_path,
                service_location_id,
                sgst,
                short_desc,
                show_actual_name,
                skip_plans,
                sort_id,
                start_at,
                state_id,
                total_sq_ft,
                website,
                zipcode,
                property_name,

                ...eleRest
              } = ele;
              propertiesData.push(eleRest);
              console.log("result_pros", eleRest);
            });

            setPropertiesList(propertiesData);
            console.log(propertiesData.length, " Properties count");
            setIsPropertyListed(false);
          } else {
            setIsPropertyListed(false);
            Swal.fire({
                title: '<strong>Error</strong>',
                html: `<i>${result.message}</i>`,
                icon: 'warning',
                timer: 1000,
                showConfirmButton: true,
              }).then(() => {
                // Optional: Additional actions can be performed here if needed
              });
            console.log("laksh", "properties not found");
          }
        });
    } else {
      console.log("laksh", "please select atleast one city");
    }

    return null;
  };
  // columns for material table
  const columns = useMemo(
    () => [
      { title: "Department", field: "depName" },
      { title: "Department Role", field: "depRoleName" },
      {
        title: "Default",
        field: "edit",
        render: (rowData) => (
          <Form.Check
            type="radio"
            id={`default-radio-${rowData.id}`} // Ensure unique IDs
            label="Default"
            style={{ color: 'orange' }}
            checked={rowData.is_default === 1}
            onChange={() => handleDefaultDeproleStatus(rowData)}
          />
        ),
      },
      {
        title: "Action",
        field: "delete",
        render: (rowData) => (
          <div onClick={() => handleDeleteDep(rowData)} style={{ cursor: 'pointer', color: 'red' }}>
            Delete
          </div>
        ),
      },
    ],
    [formData.org_dept_data]
  );

  console.log(formData.org_dept_data, "datas");
  return (
    <>
      {loader == true ? (
        <>
          <div>
            {/* page title */}
            
            {/* page body */}
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div ref={NameRef}>
                  <div className="card-body">
                    <Form
                      noValidate
                      validated={validated}
                      className="forms-sample addStaff"
                      onSubmit={handleSubmit}>
                      <div className="row">
                        {/* Staff User Name  */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <label htmlFor="name">
                              Staff User Name{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <Form.Control
                              autoFocus
                              name="name"
                              pattern="^[A-Za-z][A-Za-z0-9_\s ]*$"
                              onKeyPress={(event) => {
                                if (!/^[A-Za-z0-9_\s ]*$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Enter only Alphanumeric and Should not allow special characters other than space or underscore"
                              type="text"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                });
                              }}
                              value={formData.name}
                              id="name"
                              placeholder="Enter Staff User Name"
                              maxLength={35}
                              required={true}
                            />
                            {errors.name && (
                              <small className="text-red">{errors.name}</small>
                            )}
                            {/* <Form.Control.Feedback type="invalid">
                              Please Enter Name.
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </div>
                        {/* Date of Birth */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <label htmlFor="dob">
                              Date of Birth{" "}
                              {/* <span className="text-danger">*</span>  */}
                            </label>
                            <Form.Control
                              type="date"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  dob: e.target.value,
                                });
                              }}
                              value={formData.dob}
                              name="dob"
                              id="dob"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Age should be minimum 18 years"
                              placeholder="Select Date"
                              max={date}
                              // required
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        {/* Contact Number */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={contactNumberRef}>
                            <label htmlFor="mobile_number">
                              Contact Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  mobile_number: e.target.value,
                                });
                              }}
                              value={formData.mobile_number}
                              maxLength="10"
                              minLength="10"
                              type="tel"
                              required
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder="Enter Contact Number"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Value should not exceed or less than 10"
                              name="mobileNo"
                            />
                            {errors.mobile_number && (
                              <small className="text-red">
                                {errors.mobile_number}
                              </small>
                            )}
                            {errors.mobile_number1 && (
                              <small className="text-red">
                                {errors.mobile_number1}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Contact Number.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Email ID */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={emailRef}>
                            <label htmlFor="email">
                              Email ID <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  email: e.target.value,
                                });
                              }}
                              value={formData.email}
                              type="email"
                              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                              name="email"
                              required
                              placeholder="Enter Email ID"
                            />
                            {errors.email && (
                              <small className="text-red">{errors.email}</small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Email Id.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row">
                        {/* Address Line#1 */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={AddressoneRef}>
                            <label htmlFor="address1">
                              Address Line#1{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  address1: e.target.value,
                                });
                              }}
                              value={formData.address1}
                              as="textarea"
                              rows={3}
                              maxLength={150}
                              name="address1"
                              required
                              id="address1"
                              placeholder="Enter Address Line #1"
                            />
                            {errors.address1 && (
                              <small className="text-red">
                                {errors.address1}
                              </small>
                            )}

                            <Form.Control.Feedback type="invalid">
                              Please Enter Address 1.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Address Line#2 */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group>
                            <label htmlFor="address2">Address Line#2</label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  address2: e.target.value,
                                });
                              }}
                              value={formData.address2}
                              as="textarea"
                              rows={3}
                              maxLength={150}
                              name="address2"
                              id="address2"
                              placeholder="Enter Address Line #2"
                            />
                          </Form.Group>
                        </div>
                        {/* Country  */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={countryRef}>
                            <label htmlFor="country_id">
                              Country <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="country_id"
                              id="country_id"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  country_id: e.target.value,
                                })
                              }
                              value={formData.country_id}
                              required>
                              <option value="">Select Country</option>
                              {countryList &&
                                countryList?.map((value, key) => {
                                  return (
                                    <option key={key} value={value.id} selected>
                                      {value.name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.country_id && (
                              <small className="text-red">
                                {errors.country_id}
                              </small>
                            )}
                            <Form.Control.Feedback type="invalid">
                              Please Select Country.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* State */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={stateRef}>
                            <label htmlFor="state_id">
                              State <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="state_id"
                              id="state_id"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  state_id: e.target.value,
                                })
                              }
                              required>
                              <option value="">Select State</option>
                              {stateList?.map((data, index) => {
                                return (
                                  <option value={data.id} key={index}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Please Select State.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* City  */}
                        <div
                          className="col-md-6 col-sm-12"
                          style={{
                            display: formData.state_id ? "block" : "none",
                          }}>
                          <Form.Group ref={cityRef}>
                            <label htmlFor="city_id">
                              City <span className="text-danger">*</span>
                            </label>
                            <select
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  city_id: e.target.value,
                                })
                              }
                              className="form-control"
                              name="city_id"
                              id="city_id"
                              required>
                              <option value="">Select City</option>
                              {city?.map((data, index) => {
                                return (
                                  <option value={data.id} key={index}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                            <Form.Control.Feedback type="invalid">
                              Please Select City.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* Postal Code */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={postalcodeRef}>
                            <label htmlFor="postal_code">
                              Postal Code <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  postal_code: e.target.value,
                                });
                              }}
                              value={formData.postal_code}
                              type="phone"
                              maxLength={6}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              name="postal_code"
                              required
                              id="postal_code"
                              placeholder="Enter Postal Code"
                            />
                            {errors.postal_code1 && (
                              <small className="text-red">
                                {errors.postal_code1}
                              </small>
                            )}

                            {errors.postal_code && (
                              <small className="text-red">
                                {errors.postal_code}
                              </small>
                            )}
                            <Form.Control.Feedback type="invalid">
                              Please Enter Postal Code.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        {/* company name */}
                     
                        {/* Password  */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={passwordRef}>
                            <label htmlFor="password">
                              Password <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                });
                              }}
                              value={formData.password}
                              type={
                                passwordIsTypePassword === true
                                  ? "password"
                                  : "text"
                              }
                              name="password"
                              required
                              minLength={8}
                              id="password"
                              placeholder="Enter Password"
                              style={{
                                position: "relative",
                                border:
                                  formData.password.length < 8 &&
                                  formData.password !== "" &&
                                  "1px solid red",
                              }}
                            />
                            {passwordIsTypePassword === true ? (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={() =>
                                    setPasswordIsTypePassword(false)
                                  }>
                                  <AiOutlineEyeInvisible
                                    size="1.5em"
                                    color="#111"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={() =>
                                    setPasswordIsTypePassword(true)
                                  }>
                                  <AiOutlineEye size="1.5em" color="#111" />
                                </button>
                              </>
                            )}
                            {errors.password && (
                              <small className="text-red">
                                {errors.password}
                              </small>
                            )}
                            {errors.password_correct && (
                              <small className="text-red">
                                {errors.password_correct}
                              </small>
                            )}
                            <Form.Control.Feedback type="invalid">
                              Please Enter Password.
                            </Form.Control.Feedback>
                            {/* {formData.password.length < 8 &&
                              formData.password !== "" && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    letterSpacing: 1,
                                    fontWeight: 500,
                                  }}
                                >
                                  Min of 8 digits required
                                </p>
                              )} */}
                          </Form.Group>
                        </div>
                        {/* Confirm Password  */}
                        <div className="col-md-6 col-sm-12">
                          <Form.Group ref={confirmpasswrdRef}>
                            <label htmlFor="password_confirmation">
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password_confirmation: e.target.value,
                                });
                              }}
                              value={formData.password_confirmation}
                              type={
                                cPasswordIsTypePassword === true
                                  ? "password"
                                  : "text"
                              }
                              name="password_confirmation"
                              required
                              minLength={8}
                              id="password_confirmation"
                              placeholder="Enter Confirm Password"
                              style={{
                                position: "relative",
                                border:
                                  (formData.password_confirmation.length < 8 &&
                                    formData.password_confirmation !== "") ||
                                  (formData.password_confirmation &&
                                    formData.password_confirmation !==
                                      formData.password &&
                                    formData.password)
                                    ? "1px solid red"
                                    : null,
                              }}
                            />
                            {cPasswordIsTypePassword === true ? (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={() =>
                                    setcPasswordIsTypePassword(false)
                                  }>
                                  <AiOutlineEyeInvisible
                                    size="1.5em"
                                    color="#111"
                                  />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    position: "absolute",
                                    top: "33px",
                                    right: "20px",
                                    border: "none",
                                    background: "none",
                                    borderLeft: "1px solid #ccc",
                                  }}
                                  onClick={() =>
                                    setcPasswordIsTypePassword(true)
                                  }>
                                  <AiOutlineEye size="1.5em" color="#111" />
                                </button>
                              </>
                            )}
                            <Form.Control.Feedback type="invalid">
                              Please Enter Confirm Password.
                            </Form.Control.Feedback>{" "}
                            
                          </Form.Group>
                          {errors.password_confirmation && (
                            <small className="text-red">
                              {errors.password_confirmation}
                            </small>
                          )}
                        </div>
                        {/* Access for Creating Ticket */}
                        <div
                          className="col-md-6 col-sm-12 mt-4"
                          style={{ zIndex: "0", display: "none" }}>
                          <label className="">Access for Creating Ticket</label>
                          <Form.Check
                            checked={
                              formData.is_create_ticket == 1 ? true : false
                            }
                            className="mt-2"
                            name="is_create_ticket"
                            type="switch"
                            id="is_create_ticket"
                            onChange={AccessStatusHandle}
                            label=""
                          />
                        </div>
                        {/* Department */}
                        <div
                          className="col-md-6 col-sm-12"
                          // style={{
                          //   display: formData.state_id ? "block" : "none",
                          // }}
                        >
                          <Form.Group ref={Departmentref}>
                            <label htmlFor="dep_id">
                              Department <span className="text-danger">*</span>
                            </label>

                            <select
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  dep_id: e.target.value,
                                  dep_role_id: "",
                                })
                              }
                              className="form-control"
                              name="dep_id"
                              id="dep_id"
                              value={formData.dep_id}
                              // required
                            >
                              <option value="">
                                Select Organization Department
                              </option>
                              {serviceDep?.map((data, index) => {
                                return (
                                  <option value={data.id} key={index}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                            {/* <Form.Control.Feedback type="invalid">
                              Please Select Department.
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </div>
                        {/* Department Roles*/}
                        {formData.dep_id ||
                        formData.org_dept_data.length > 0 ? (
                          <div
                            className="col-md-6 col-sm-12 "
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}>
                            <div className="col-md-9 col-sm-12 p-0">
                              <Form.Group ref={DepartmentroleRef}>
                                <label htmlFor="dep_role_id">
                                  Department Role{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                <select
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      dep_role_id: e.target.value,
                                    })
                                  }
                                  value={formData.dep_role_id}
                                  className="form-control"
                                  name="dep_role_id"
                                  id="dep_role_id"
                                  // required
                                >
                                  <option value="">
                                    Select Organization Department Role
                                  </option>

                                  {depRoles?.dep_roles?.map((data, index) => {
                                    return (
                                      <option value={data.id} key={index}>
                                        {data.dep_role_name}
                                      </option>
                                    );
                                  })}
                                </select>
                                {/* <Form.Control.Feedback type="invalid">
                                Please Select Department Role.
                              </Form.Control.Feedback> */}
                              </Form.Group>
                            </div>

                            <div
                              className="col-md-2 col-sm-12  p-0"
                              style={{
                                alignSelf: "center",
                                marginBottom: "5px",
                                marginRight: "10px",
                                marginLeft: "0px",
                                display:
                                  !formData.dep_id || !formData.dep_role_id
                                    ? "none"
                                    : "block",
                              }}
                              onClick={AddDepartments}>
                              <Button>Add</Button>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-5 col-sm-12"></div>
                        )}
                        {/* show table for dep_roles and dep*/}
                       
                        <div
                          className="col-md-12  col-sm-12  p-0"
                          style={{
                            display:
                              formData.org_dept_data.length > 0
                                ? "block"
                                : "none",
                          }}>
                           <Table striped bordered hover>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {formData.org_dept_data.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>
                {column.render ? column.render(rowData) : rowData[column.field]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>

                        </div>
                        {errors.org_dept_data && (
                          <small className="text-red">
                            {errors.org_dept_data}
                          </small>
                        )}
                        {errors.compulproperty && (
                          <small
                            style={{ marginTop: "20px" }}
                            className="text-red">
                            {errors.compulproperty}
                          </small>
                        )}
                        {/* Property Access based on location */}
                        <div className="col-md-12 col-sm-12 my-4">
                          <b>Property Access based on location</b>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <label>
                            State <span className="text-danger">*</span>
                          </label>
                          <Select
                            onChange={handleStatesName}
                            options={stateOptions}
                            isMulti
                          />
                          {/* <p
                            style={{
                              display: isPropertySelected ? "block" : "none",
                              color: "red",
                              marginTop: "0.5rem",
                            }}
                          >
                            Please select atleast one property
                          </p> */}
                        </div>
                        {/* Cities */}
                        <div className="col-md-6 col-sm-12">
                          <label>Cities</label>

                          <Select
                            onChange={handleCityNames}
                            options={filteredCityOptions}
                            isMulti
                            isDisabled={stateId ? false : true}
                          />

                          {/* <p
                      role="button"
                      className="text-right text-primary"
                      onClick={handleLocation}
                      style={{ display: cityId ? "block" : "none" }}
                    >
                      <b> Search Properties</b>
                    </p> */}
                        </div>{" "}
                        <div className="col-md-12">
                          <button
                            className="user_management_styled_button"
                            onClick={handleLocation}
                            style={{ display: cityId ? "block" : "none" }}
                            type="button">
                            {isPropertyListed
                              ? "Loading ..."
                              : "Search Properties"}
                          </button>
                        </div>
                        {confirmToggle && (
                          <div className="col-md-12 col-sm-12 mt-2">
                            <label>Properties</label>

                            <Select
                              onChange={handlePropertyNames}
                              options={propertiesList}
                              isMulti
                            />
                            {!propertyId && (
                              <small style={{ color: "red" }}>
                                * Please select Property
                              </small>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {" "}
                        <div className="col-md-6 col-sm-12 d-flex mt-4">
                          <div className="">
                            <input
                              type="radio"
                              id="indivitualUser"
                              name="inviteGuestFor"
                              value="cathead"
                              onChange={handleRadioChange}
                              checked={formData.isCatHead === 1}
                            />
                            <label
                              htmlFor="indivitualUser"
                              className="visitors_modal_lable_radio">
                              CAT Head
                            </label>
                          </div>
                          <div className="">
                            <input
                              type="radio"
                              id="corporateUser"
                              name="inviteGuestFor"
                              value="catlead"
                              onChange={handleRadioChange}
                              checked={formData.isCatLead === 1}
                            />
                            <label
                              htmlFor="corporateUser"
                              className="visitors_modal_lable_radio">
                              CAT Lead
                            </label>
                          </div>
                        </div>
                        {/* Enable/Disable */}
                        <div
                          className="col-md-6 col-sm-12 mt-4"
                          style={{ zIndex: "0" }}>
                          <label className="">Enable/Disable</label>
                          <Form.Check
                            checked={formData.status == 1 ? true : false}
                            className="mt-2"
                            name="status"
                            type="switch"
                            id="status"
                            onChange={statusHandle}
                            label=""
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-wrap justify-content-end mr-2 mt-4">
                        <button
                          type="button"
                          className="btn btn-light rounded-0 rounded-0"
                          onClick={(e) => handleClear(e)}>
                          Clear
                        </button>

                        <button
                          type="submit"
                          className="btn btn-primary ml-2 rounded-0">
                          Onboard
                        </button>
                        {/* )} */}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ToastBRH
            duration={toastObject.duration}
            onSetOpen={setSnackOpen}
            open={snackOpen}
            variant={toastObject.variant}>
            {toastObject.message}
          </ToastBRH> */}
        </>
      ) : (
        <>
          <Container
            style={{
              height: "60vh",
            }}
            className=" d-flex align-items-center justify-content-center ">
            <ScaleLoader size={100} color="orange" />
          </Container>
        </>
      )}
    </>
  );
};

export default AddOrganizationStaff;
