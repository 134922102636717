import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import URL from "../../../API/Api";
import "./TaskForm.css";

const TaskForm = ({
  FlowTask,
  Editform,
  editTaskFlowOrder,
  formData,
  setFormData,
  handleSubmit,
  buttonName,
  handleClose,
}) => {
  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]); // For departments
  const [roleOptions, setRoleOptions] = useState([]); // For roles
  const [loading, setLoading] = useState(true);
  const [ProcessGetvalue, setProcessGetvalue] = useState();
  const [TaskNumberFlow, setTaskNumberFlow] = useState();
  const [TasksField, setTasksField] = useState([]);
  const [organizations, setOrganizations] = useState([]); // State for organizations

  // const
  // console.log(Editform,"Editform");

  // console.log(FlowTask,"twinle")
  // console.log(processOptions,"processOptions")

  // Fetch processes and departments with roles from the API when the component mounts
  useEffect(() => {
    const fetchProcessesAndDepartments = async () => {
      setLoading(true);
      try {
        // Fetch processes
        const processResponse = await URL.get("api/allprocess");
        if (processResponse.data && processResponse.data.status) {
          const processData = processResponse.data.data;
          // console.log(processResponse,"cvvb")
          const formattedProcessOptions = processData.map((process, index) => ({
            value: process.id,
            label: process.process_name,
            key: `${process.process_name}-${index}`, // Unique key
          }));
          setProcessOptions(formattedProcessOptions);
        }

        // Fetch departments with roles
        const departmentResponse = await URL.get(
          "user-management/api/v1/orgdept"
        );
        if (departmentResponse.data && departmentResponse.data.status) {
          const departmentData = departmentResponse.data.data;
          const formattedDepartments = departmentData.map((dept) => ({
            value: dept.id,
            label: dept.department_name,
            roles: dept.organization_role_department.map((role) => ({
              value: role.id,
              label: role.dep_role_name,
            })),
            key: dept.id, // Unique key for department
          }));
          setDepartmentOptions(formattedDepartments);
        }
      } catch (error) {
        console.error("Error fetching processes or departments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProcessesAndDepartments();
  }, []);

  //  console.log(TaskNumberFlow,"mjh")

  // useEffect(()=>{

  // },[ProcessGetvalue])

  const GetTaskFlowing = (d) => {
    // console.log(d,"lkjhgg");
    const selectedProcess = FlowTask.find(
      (process) => process.id === parseInt(d)
    );
    if (selectedProcess) {
      // console.log(selectedProcess?.tasks,"mkkkllll")s
      setTasksField(selectedProcess.tasks);
      if (selectedProcess?.tasks?.length === 0) {
        setTaskNumberFlow(1); // Return 1 if the array is empty
      } else {
        const lastObject =
          selectedProcess?.tasks[selectedProcess?.tasks?.length - 1];
        const gettheFlowOrder = lastObject.task_flow_order; // Return the value of number_flow
        const AddCountFlowOrder = gettheFlowOrder + 1;

        // console.log(AddCountFlowOrder,"getorder");
        setTaskNumberFlow(parseInt(AddCountFlowOrder));
      }
      // setNumberFlow(AddCountFlowOrder);
    } else {
      setTasksField([]); // Reset tasks if no process is found
      setTaskNumberFlow(1);
    }
  };
  console.log(roleOptions, "jkll");
  // Handle input changes
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    // console.log(e,"mmnnn")
    if (e.target.name === "process_id") {
      // console.log(e.target.value,"maths")
      setProcessGetvalue(e.target.value);
      GetTaskFlowing(parseInt(e.target.value));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Fetch roles when the department is changed
    if (name === "dept_id") {
      const selectedDepartmentId = parseInt(value, 10); // Convert the value to an integer
      const selectedDepartment = departmentOptions.find(
        (dept) => dept.value === selectedDepartmentId
      );

      // Debugging logs
      // console.log("Selected Department ID:", selectedDepartmentId); // Log selected department ID
      // console.log("Available Departments:", departmentOptions); // Log available departments

      if (selectedDepartment) {
        setRoleOptions(selectedDepartment.roles); // Set roles based on selected department

        // Automatically set dept_role_id to the first role or clear if no roles
        const newRoleId =
          selectedDepartment.roles.length > 0
            ? selectedDepartment.roles[0].id
            : null;
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: newRoleId, // Set the department role ID
        }));

        console.log("Roles for Selected Department:", selectedDepartment.roles); // Log roles
      } else {
        setRoleOptions([]); // Clear roles if no department is selected
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: null, // Clear dept_role_id if no department
        }));
        console.log("No roles found for this department.");
      }
    }
  };
  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("api/allorg");
      const organizationData = response.data.data;
      console.log("organizationData", organizationData);
      setOrganizations(organizationData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  }, []);

  return (
    <Container className="task-form-container">
      <h2 className="text-center mb-4">Task</h2>
      <Form>
        <Row>
          {/* Organization Name Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="org_id">
              <Form.Label>Organization</Form.Label>
              <Form.Control
                as="select"
                name="org_id"
                value={formData.org_id}
                onChange={handleChange}
                className={`select-placeholder ${
                  formData.organization === "" ? "placeholder" : ""
                }`}
                required>
                {/* Placeholder option with grey text */}
                <option value="" style={{ color: "#525658" }}>
                  Select Organization
                </option>
                {/* Map through organizations */}
                {organizations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.org_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          {/* Process Name Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="process_id">
              <Form.Label>Process Name</Form.Label>
              <Form.Control
                as="select"
                name="process_id"
                value={formData.process_id}
                onChange={handleChange}
                required
                disabled={loading}>
                <option value="">Select Process</option>
                {processOptions.map((process) => (
                  <option
                    key={process.key} // Use the unique key here
                    value={process.value}
                    style={{ textTransform: "capitalize" }}>
                    {process.label}
                  </option>
                ))}
              </Form.Control>
              {loading && <small>Loading processes...</small>}
            </Form.Group>
          </Col>
          {/* Department Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="dept_id">
              <Form.Label>Department</Form.Label>
              <Form.Control
                as="select"
                name="dept_id"
                value={formData.dept_id}
                onChange={handleChange}
                required>
                <option value="">Select Department</option>
                {departmentOptions.map((dept) => (
                  <option key={dept.key} value={dept.value}>
                    {dept.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          {/* Department Role Dropdown */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="dept_role_id">
              <Form.Label>Department Role</Form.Label>
              <Form.Control
                as="select"
                name="dept_role_id"
                value={formData.dept_role_id}
                onChange={handleChange}
                required
                disabled={roleOptions.length === 0} // Disable if no roles
              >
                <option value="">Select Department Role</option>
                {roleOptions.map((role, index) => (
                  <option key={`${role.value}-${index}`} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          {/* Task Name */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="task_name">
              <Form.Label>Task Name</Form.Label>
              <Form.Control
                type="text"
                name="task_name"
                value={formData.task_name}
                placeholder="Task Name"
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          {/* User Dropdown */}

          {/* SLA Resolution Time */}
          <Col md={6} style={{ padding: "10px" }}>
            <Form.Group controlId="sla_resolution_time">
              <Form.Label>SLA Resolution Time</Form.Label>
              <Form.Control
                type="time"
                name="sla_resolution_time"
                value={formData.sla_resolution_time}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          {/* Task Flow Order */}
          <Col md={6} style={{ padding: "10px" }}>
            {Editform === true ? (
              <Form.Group controlId="task_flow_order">
                <Form.Label>Task Flow Order</Form.Label>
                <Form.Control
                  disabled
                  type="number"
                  name="task_flow_order"
                  value={editTaskFlowOrder}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            ) : (
              <Form.Group controlId="task_flow_order">
                <Form.Label>Task Flow Order</Form.Label>
                <Form.Control
                  disabled
                  type="number"
                  name="task_flow_order"
                  value={TaskNumberFlow}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            )}
          </Col>
          {/* Is Document Required Checkbox */}
          <Col md={6} style={{ padding: "50px 10px" }}>
            <Form.Group controlId="is_doc_upload_required">
              <Form.Check
                type="checkbox"
                label="Is Document Required"
                name="is_doc_upload_required"
                checked={formData.is_doc_upload_required}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          {/* Task Description */}
          <Col md={12} style={{ padding: "10px" }}>
            <Form.Group controlId="task_description">
              <Form.Label>Task Description (if any)</Form.Label>
              <Form.Control
                as="textarea"
                name="task_description"
                rows={4}
                value={formData.task_description}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>

        {/* Action Buttons */}
        <div className="form-actions mb-4">
          <Button
            variant="outline-light"
            type="reset"
            className="cancel-btn"
            onClick={handleClose}>
            Cancel Task
          </Button>
          <Button
            variant="outline-light"
            // type="submit"
            className="create-btn"
            onClick={() => handleSubmit(TaskNumberFlow, formData)}>
            {buttonName}
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default TaskForm;
