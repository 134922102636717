import React, { useEffect, useState } from "react";
import OrganizationForm from "./OrganizationForm";
import URL from "../../../API/Api";
import Swal from "sweetalert2";

const AddOrganization = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    organizationName: "",
  });

  useEffect(() => {}, []);

  // Handle form submission

  const handleSubmit = async (e) => {
    e.preventDefault();

    const organizationData = {
      org_name: formData.organizationName,
      status: 1,
    };

    try {
      const response = await URL.post("api/org", organizationData);
      // console.log("Organization Data Successfully Submitted:", response.data);

      // Show success toaster
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Organization Data Successfully ",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#28a745",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // Clear form data
      setFormData({
        organizationName: "",
      });
      // Call handleClose to hide the form after successful submission
      handleClose();
    } catch (error) {
      console.error("Error submitting organization data:", error);

      // Show error toaster
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error submitting organization data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div>
      <OrganizationForm
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        buttonName="Add Organization"
        handleClose={handleClose}
      />
    </div>
  );
};

export default AddOrganization;
