import React, { useState } from "react";
import TaskForm from "./TaskForm";
import URL from "../../../API/Api";
import Swal from "sweetalert2";

const AddForm = ({ handleClose, FlowTask }) => {
  const [formData, setFormData] = useState({
    task_flow_order: null,
    task_name: "",
    task_description: "",
    dept_id: "",
    org_id: "",
    dept_role_id: null,
    sla_resolution_time: "",
    is_doc_upload_required: false,
    status: true,
    due_date: "",
    process_id: "",
  });

  const formatDate = (date) => {
    const pad = (num) => (num < 10 ? "0" + num : num); // Helper function to pad single digits
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // Get current date and time
  const currentDate = new Date();
  const formattedCurrentDate = formatDate(currentDate);

  const handleSubmit = async (e, d) => {
    //e.preventDefault();
    console.log(e, d, "njkll");

    // Ensure SLA resolution time is properly formatted
    let slaResolutionTime = formData.sla_resolution_time;
    if (slaResolutionTime && !slaResolutionTime.includes(":00")) {
      slaResolutionTime += ":00"; // Append ":00" if not present
    }

    // Calculate due date based on SLA resolution time
    // const today = new Date();
    // const [hours, minutes] = slaResolutionTime.split(":").map(Number);
    // today.setHours(hours, minutes, 0, 0);
    // const dueDate = formatDate(today);

    const data = {
      task_flow_order: e,
      task_name: formData.task_name,
      task_description: formData.task_description,
      dept_id: formData.dept_id,
      org_id: formData.org_id,
      dept_role_id: formData.dept_role_id,
      sla_resolution_time: slaResolutionTime,
      is_doc_upload_required: 1,
      status: 1,
      // due_date: dueDate,
      process_id: formData.process_id,
    };

    console.log(data, "common");

    try {
      const response = await URL.post("api/task", data);

      // Show success toaster
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Task Data Successfully Submitted",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#28a745",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      setFormData({
        task_flow_order: "",
        task_name: "",
        task_description: "",
        dept_id: "",
        org_id: "",
        dept_role_id: null,
        sla_resolution_time: "",
        is_doc_upload_required: false,
        status: true,
        due_date: "",
        process_id: "",
      });

      handleClose();
    } catch (error) {
      console.error("Error creating task", error);
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error creating the task. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  return (
    <TaskForm
      FlowTask={FlowTask}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      buttonName="Create Task"
    />
  );
};

export default AddForm;
