import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Modal,
  Pagination,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { FaPen, FaTrash } from "react-icons/fa";
import URL from "../API/Api";
import Swal from "sweetalert2";
import AddProcess from "../components/FormComponents/ProcessForm/AddProcess";
import EditProcess from "../components/FormComponents/ProcessForm/EditProcess";
import Select from "react-select";

const Process = () => {
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [processes, setProcesses] = useState([]); // Store process data
  const [Flowprocesses, setFlowProcesses] = useState([]); // Store process data
  const [allorgwithProcess, setAllorgwithProcess] = useState([]); // Store process data
  const [organizationNames, setOrganizationNames] = useState({}); // Map to store organization names by ID
  const [departmentNames, setDepartmentNames] = useState({}); // Map to store department names by ID
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(5);

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  //editprocess States
  const [editprName, seteditprName] = useState("");
  const [editOrgId, seteditOrgId] = useState("");
  const [editdept, seteditdept] = useState([]);

  const [editprFloOrder, seteditprFloOrder] = useState("");
  const [editactId, seteditactId] = useState("");
  const [editactFloOrder, seteditactFloOrder] = useState("");

  //process

  const [processNumberFlow, setProcessNumberFlow] = useState(1); // Default value of 1
  const [formData, setFormData] = useState({
    orgName: "",
    processName: "",
    processFlowOrder: 1,
    department: [],
  });
  const [departments, setDepartments] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [buttonName, setButtonName] = useState("Create Process"); // Default button name

  // Fetch processes from API
  const fetchProcesses = async (page = 1) => {
    try {
      const response = await URL.get(`api/process`, {
        params: { limit: limit, page: page },
      });
      const responseData = response.data;
      setProcesses(responseData.data); // Use empty array as fallback
      console.log(Flowprocesses, "myprocess");
      setCurrentPage(responseData.pagination.current_page);
      setTotalPages(responseData.pagination.last_page);

      // Fetch organization names for all unique org_ids in the processes
      const orgIds = [
        ...new Set((responseData.data || []).map((p) => p.org_id)),
      ];
      await fetchOrganizationNames(orgIds);

      // Fetch department names for all unique dept_ids in the processes
      const deptIds = [
        ...new Set((responseData.data || []).flatMap((p) => p.dept_id)),
      ];
      await fetchDepartmentNames(deptIds);
    } catch (error) {
      console.error("Error fetching processes:", error);
      setProcesses([]); // Set to empty array in case of error
    } finally {
    }
  };

  function getLastNumberFlow(arr) {
    if (arr.length === 0) {
      setProcessNumberFlow(1); // Return 1 if the array is empty
    } else {
      // Get the last object in the array
      const lastObject = arr[arr.length - 1];
      const gettheFlowOrder = lastObject.process_flow_order; // Return the value of number_flow
      const AddCountFlowOrder = gettheFlowOrder + 1;

      // console.log(AddCountFlowOrder, "getorder");
      setProcessNumberFlow(AddCountFlowOrder);
    }
  }

  // Fetch organization names based on org_ids
  const fetchOrganizationNames = async (orgIds) => {
    const orgNameMapping = {};
    try {
      const fetchPromises = orgIds.map((id) => URL.get(`api/org/${id}`));
      const orgResponses = await Promise.all(fetchPromises);

      orgResponses.forEach((response) => {
        const orgData = response.data?.data;
        if (orgData) {
          orgNameMapping[orgData.id] = orgData.org_name;
        }
      });

      setOrganizationNames(orgNameMapping);
    } catch (error) {
      console.error("Error fetching organization names:", error);
    }
  };
  // Handle Toggle Switch Change
  const handleToggleChange = async (e, process) => {
    try {
      let status = e.target.checked ? 1 : 0;

      let data = {
        status: status,
      };

      // console.log(process, "process");
      await URL.put(`api/process/${process.id}`, data);

      fetchProcesses(currentPage);
    } catch (error) {
      console.error("Error updating process status:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the process status.",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  // Fetch department names based on dept_ids
  const fetchDepartmentNames = async (deptIds) => {
    const deptNameMapping = {};
    try {
      const fetchPromises = deptIds.map((id) =>
        URL.get(`user-management/api/v1/orgdept/${id}`)
      );
      const deptResponses = await Promise.all(fetchPromises);

      deptResponses.forEach((response) => {
        const deptData = response.data?.data;
        if (deptData) {
          deptNameMapping[deptData.id] = deptData.department_name;
        }
      });

      setDepartmentNames(deptNameMapping);
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  useEffect(() => {
    fetchProcesses(currentPage);
  }, [currentPage]);

  useEffect(() => {
    fetchAll();
  }, [showProcessModal]);
  const fetchAll = async () => {
    try {
      const response = await URL.get(`api/allprocess`);
      const responseData = response.data;
      setFlowProcesses(responseData.data); // Use empty array as fallback
      // console.log(Flowprocesses,"myprocess");
      getLastNumberFlow(Flowprocesses);
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  const handleDeleteProcessClick = (process) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this process!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await URL.delete(`api/process/${process.id}`);
          Swal.fire({
            title: "Deleted!",
            text: response.data.message,
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
          fetchProcesses();
        } catch (error) {
          console.error("Error deleting process:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting the process.",
            icon: "error",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShowProcess = () => setShowProcessModal(true);
  const handleCloseProcess = () => setShowProcessModal(false);

  const handleEditClick = (processId) => {
    setSelectedProcessId(processId);
    GetProcessDetail(processId);
    setShowEditModal(true);
  };

  const handleClose = () => {
    setShowEditModal(false);
    setSelectedProcessId(null); // Reset selected process id
  };

  const GetProcessDetail = async (processId) => {
    try {
      const response = await URL.get(`/api/process/${processId}`);
      const processData = response.data.data;
      // console.log("mydata", processData)
      seteditactId(processData.org_id);
      seteditactFloOrder(processData.process_flow_order);
      seteditdept(processData.dept_id);
      seteditOrgId(processData.org_id);
      seteditprFloOrder(processData.process_flow_order);
      seteditprName(processData.process_name);
    } catch (error) {
      console.error("Error fetching process data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to fetch process data.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  //process
  // console.log(editOrgId,"rrrrr")

  // Fetch department data from API
  const fetchDepartments = useCallback(async () => {
    try {
      const response = await URL.get("user-management/api/v1/orgdept");
      const departmentData = response.data.data;
      setDepartments(departmentData);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  }, []);

  // Fetch organization data from API
  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("api/allorg");
      const organizationData = response.data.data;
      setOrganizations(organizationData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  }, []);

  // Fetch data when the component mounts
  useEffect(() => {
    fetchDepartments();
    fetchOrganizations();
  }, [fetchDepartments, fetchOrganizations]);

  // Handle department multi-select changes
  const handleDepartmentChange = (selectedOptions) => {
    const departmentIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    seteditdept(departmentIds);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      editOrgId,
      editprName,
      editdept,
      editprFloOrder,
      EditProcess,
    };

    console.log("Initial Data:", data);

    const transformedData = {
      process_name: data.editprName,
      org_id: data.editOrgId,
      process_flow_order: data.editprFloOrder,
      status: 1,
      dept_id: data.editdept,
    };

    console.log("Transformed Data:", transformedData);

    try {
      const response = await URL.put(
        `/api/process/${selectedProcessId}`,
        transformedData
      );
      console.log("Process Data Successfully Updated:", response.data);
      fetchProcesses();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message || "Process Data Successfully Updated",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#28a745",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });

      handleClose();
    } catch (error) {
      console.error("Error updating process data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error updating process data. Please try again.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  // Handle closing the form (if needed)
  const handleClosee = () => {
    // Reset form data and other state variables
  };

  const SettingProcessOrderHandler = (org_id) => {
    // console.log(org_id, "org_id");
    // console.log(editactId, "org_id2");
    URL.get("/api/org_process")
      .then((res) => {
        setAllorgwithProcess(res.data.data);

        // Find the organization directly from the response data
        const organization = res.data.data.find((org) => org.id == org_id);
        // console.log(organization, "getobj");
        if (organization && organization.id === editactId) {
          // Example action
          // setSomeState(organization); // Uncomment if you want to set a state with the organization
          console.log("Organization matches selected ID:", organization);

          seteditprFloOrder(parseInt(editactFloOrder));
        } else {
          console.log("No match found or organization is undefined.");
          if (organization?.process?.length === 0) {
            seteditprFloOrder(1); // Return 1 if the array is empty
          } else {
            const lastObject =
              organization?.process[organization?.process?.length - 1];
            const gettheFlowOrder = lastObject.process_flow_order; // Return the value of number_flow
            const AddCountFlowOrder = gettheFlowOrder + 1;

            // console.log(AddCountFlowOrder,"getorder");
            seteditprFloOrder(parseInt(AddCountFlowOrder));
          }
        }
      })
      .catch((err) => console.log(err));
  };
  // ------
  // //task
  //   -------

  return (
    <div className="main-content">
      <div className="d-flex justify-content-end mb-3">
        {/* Create Process button */}
        <Button
          onClick={handleShowProcess}
          style={{
            background:
              "linear-gradient(106.37deg, #e07b39 19.63%, #ff8c00 51.55%, #fca61f 90.85%)",
            border: "none",
            marginRight: "10px",
          }}>
          + Process
        </Button>
      </div>
      {/* Process Table */}
      <div className="process-table container mt-5">
        <h3 className=" mb-4">Processes</h3>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Process Name</th>
              <th className="text-center">Organization</th>
              <th className="text-center">Process Flow Order</th>
              <th>Departments</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {processes && processes.length > 0 ? (
              processes.map((process, index) => (
                <tr key={index}>
                  <td>{process.process_name}</td>
                  <td className="text-center">
                    {organizationNames[process.org_id] || "N/A"}
                  </td>
                  <td className="text-center">{process.process_flow_order}</td>
                  <td>
                    {Array.isArray(process.dept_id) &&
                    process.dept_id.length > 0 ? (
                      process.dept_id.map((deptId, deptIndex) => (
                        <div key={deptIndex}>
                          {departmentNames[deptId] || "Unknown Department"}
                        </div>
                      ))
                    ) : (
                      <span>No Departments</span>
                    )}
                  </td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${process.id}`}
                      // label={process.status ? "Active" : "Inactive"}
                      checked={process.status}
                      onChange={(e) => handleToggleChange(e, process)}
                    />
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}>
                    <FaPen
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        color: "green",
                      }}
                      onClick={() => handleEditClick(process.id)}
                    />
                    {/* <FaTrash
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => handleDeleteProcessClick(process)}
                      /> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No processes found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center mt-4">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      {/* Process Modal */}
      <Modal
        show={showProcessModal}
        onHide={handleCloseProcess}
        centered
        size="lg">
        <Modal.Body>
          <AddProcess
            processNumberFlow={processNumberFlow}
            handleClose={handleCloseProcess}
          />
        </Modal.Body>
      </Modal>

      {/* Modal for editing process */}
      {showEditModal && (
        <Modal show={showEditModal} onHide={handleClose} centered size="lg">
          <Modal.Body>
            <Container className="process-form-container">
              <h2 className="text-center mb-4">Process</h2>
              <Form onSubmit={handleSubmit}>
                <Row>
                  {/* Organization Dropdown */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="orgName">
                      <Form.Label>Organization</Form.Label>
                      <Form.Control
                        as="select"
                        name="orgName"
                        value={editOrgId}
                        onChange={(e) => {
                          const ds2 = e.target.value;
                          SettingProcessOrderHandler(ds2);
                          seteditOrgId(ds2);
                        }}
                        // onChange={(e)=>seteditOrgId(e.target.value)}
                        className={`select-placeholder ${
                          formData.organization === "" ? "placeholder" : ""
                        }`}
                        required>
                        <option value="" style={{ color: "#525658" }}>
                          Select Organization
                        </option>
                        {organizations.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.org_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  {/* Process Name */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="processName">
                      <Form.Label>Process Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="processName"
                        value={editprName}
                        placeholder="Enter process name"
                        onChange={(e) => seteditprName(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>

                  {/* Process Flow Order */}
                  <Col md={4} style={{ padding: "10px" }}>
                    <Form.Group controlId="processFlowOrder">
                      <Form.Label>Process Flow Order</Form.Label>
                      <Form.Control
                        type="number"
                        name="processFlowOrder"
                        placeholder="Enter process flow order"
                        value={editprFloOrder}
                        onChange={(e) => seteditprFloOrder(e.target.value)}
                        required
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  {/* Department Dropdown */}
                  <Form.Group controlId="department">
                    <Form.Label>Department</Form.Label>
                    <div className="department-select-wrapper">
                      <Select
                        isMulti
                        name="department"
                        value={departments
                          .filter((dept) => editdept.includes(dept.id))
                          .map((dept) => ({
                            label: dept.department_name,
                            value: dept.id,
                          }))}
                        options={departments.map((dept) => ({
                          label: dept.department_name,
                          value: dept.id,
                        }))}
                        onChange={handleDepartmentChange}
                        classNamePrefix="select"
                        placeholder="Select Department"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            background: "#e7e7e7",
                            border: "none",
                            boxShadow: state.isFocused
                              ? "0 0 0 2px rgba(0, 123, 255, 0.25)"
                              : null,
                            borderRadius: "34px",
                            padding: "2px",
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            borderRadius: "34px",
                            zIndex: 100,
                          }),
                          multiValue: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: "#e9ecef",
                            borderRadius: "20px",
                          }),
                          multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            color: "#495057",
                          }),
                          multiValueRemove: (baseStyles) => ({
                            ...baseStyles,
                            color: "#495057",
                            "&:hover": {
                              backgroundColor: "#d9534f",
                              color: "#fff",
                            },
                          }),
                        }}
                      />
                    </div>
                  </Form.Group>
                </Row>

                {/* Action Buttons */}
                <div className="form-actions mb-4">
                  <Button
                    variant="outline-light"
                    type="reset"
                    className="cancel-btn"
                    onClick={handleClose}>
                    Cancel Process
                  </Button>
                  <Button
                    variant="outline-light"
                    type="submit"
                    className="create-btn">
                    Update Process
                  </Button>
                </div>
              </Form>
            </Container>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Process;
