import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import DepartmentTree from './DepartmentTree';
import Staff from './Staff';

const MasterOrganization = () => {
  const [key, setKey] = useState(() => {
    // Retrieve the initial key from localStorage
    return localStorage.getItem('activeTab') || 'home'; // Default to 'home' if not found
  });

  useEffect(() => {
    // Store the selected tab key in localStorage whenever it changes
    localStorage.setItem('activeTab', key);
  }, [key]);

 
  return (
    <div>
         <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="Department">
            <DepartmentTree />
      </Tab>

      <Tab eventKey="staff" title="Staff">
            <Staff />
      </Tab>
      
       
    </Tabs>
    </div>
  )
}

export default MasterOrganization