import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Modal,
  Pagination,
  Table,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";

import { FaPen, FaTrash } from "react-icons/fa";
import URL from "../API/Api";
import Swal from "sweetalert2";
import AddTask from "../components/FormComponents/TaskForm/AddTask";
import EditTask from "../components/FormComponents/TaskForm/EditTask";

const Task = () => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);
  const [FlowTask, setFlowTask] = useState([]); // Store process data
  const [selectedTask, setSelectedTask] = useState(null);
  const [Tasks, setTasks] = useState([]); // Store process data
  const [organizationNames, setOrganizationNames] = useState({}); // Map to store organization names by ID
  const [departmentNames, setDepartmentNames] = useState({}); // Map to store department names by ID
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(5);
  const [processNumberFlow, setprocessNumberFlow] = useState();

  const [processOptions, setProcessOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ProcessGetvalue, setProcessGetvalue] = useState();
  const [TaskNumberFlow, setTaskNumberFlow] = useState();
  const [TasksField, setTasksField] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [formData, setFormData] = useState({
    org_id: "",
    process_id: "",
    dept_id: "",
    dept_role_id: "",
    task_name: "",
    sla_resolution_time: "",
    task_flow_order: "",
    is_doc_upload_required: false,
    task_description: "",
  });

  //edit form states
  const [editactprcsID, seteditactprcsID] = useState("");
  const [editactFloOrder, seteditactFloOrder] = useState("");
  const [editFloOrder, seteditFloOrder] = useState("");
  const [editTaskName, seteditTaskName] = useState("");
  const [editTaskDesc, seteditTaskDesc] = useState("");
  const [editDepID, seteditDepID] = useState("");

  const [editOrgID, seteditOrgID] = useState("");
  const [editDeptRoleID, seteditDeptRoleID] = useState("");
  const [editSLA, seteditSLA] = useState("");
  const [allPrcswithTask, setAllPrcswithTask] = useState([]);

  const [editDocum, seteditDocum] = useState("");
  const [editstatus, seteditstatus] = useState("");
  const [editDueDate, seteditDueDate] = useState("");
  const [editPrcsID, seteditPrcsID] = useState("");

  // Fetch Tasks from API
  const fetchTasks = async (page = 1) => {
    try {
      const response = await URL.get(`api/task`, {
        params: { limit: limit, page: page },
      });
      const responseData = response.data;
      // console.log(responseData.data,"jkl")
      setCurrentPage(responseData.data.current_page);
      setTotalPages(responseData.data.last_page);
      setTasks(responseData?.data?.data); // Use empty array as fallback
      // console.log(responseData.data.data)

      // Fetch organization names for all unique org_ids in the Tasks
      const orgIds = [...new Set(responseData.data.map((p) => p.org_id))];
      await fetchOrganizationNames(orgIds);

      // Fetch department names for all unique dept_ids in the Tasks
      const deptIds = [...new Set(responseData.data.flatMap((p) => p.dept_id))];
      await fetchDepartmentNames(deptIds);
    } catch (error) {
      // console.error("Error fetching Tasks:", error);
      // setTasks([]); // Set to empty array in case of error
    }
  };

  // Fetch organization names based on org_ids
  const fetchOrganizationNames = async (orgIds) => {
    const orgNameMapping = {};
    try {
      const fetchPromises = orgIds.map((id) => URL.get(`api/org/${id}`));
      const orgResponses = await Promise.all(fetchPromises);

      orgResponses.forEach((response) => {
        const orgData = response.data?.data;
        if (orgData) {
          orgNameMapping[orgData.id] = orgData.org_name;
        }
      });

      setOrganizationNames(orgNameMapping);
    } catch (error) {
      console.error("Error fetching organization names:", error);
    }
  };

  // Fetch department names based on dept_ids
  const fetchDepartmentNames = async (deptIds) => {
    const deptNameMapping = {};
    try {
      const fetchPromises = deptIds.map((id) =>
        URL.get(`user-management/api/v1/orgdept/${id}`)
      );
      const deptResponses = await Promise.all(fetchPromises);

      deptResponses.forEach((response) => {
        const deptData = response.data?.data;
        if (deptData) {
          deptNameMapping[deptData.id] = deptData.department_name;
        }
      });

      setDepartmentNames(deptNameMapping);
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  const handleToggleChange = async (e, tsk) => {
    try {
      console.log("hit");
      let status = e.target.checked ? 1 : 0;

      let data = {
        status: status,
      };

      console.log(data, "task");
      await URL.put(`api/task/${tsk.id}`, data);
      fetchTasks();
    } catch (error) {
      console.error("Error updating task status:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the task status.",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const handleDeleteTaskClick = (task) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to recover this task!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await URL.delete(`api/task/${task.id}`); // Adjust the API endpoint as needed
          Swal.fire({
            title: "Deleted!",
            text: response.data.message, // Show the success message from the API response
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
          fetchTasks(); // Call the function to refresh the task list
        } catch (error) {
          console.error("Error deleting task:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting the task.",
            icon: "error",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchTasks(currentPage); // Fetch Tasks when component loads or page changes
  }, [currentPage]);

  useEffect(() => {
    fetchAll();
  }, [showTaskModal]);

  const fetchAll = async () => {
    try {
      const response = await URL.get(`api/allprocess_task`);
      const responseData = response.data;
      console.log(responseData.data, "mytkkkkk");

      setFlowTask(responseData.data); // Use empty array as fallback
      // getLastNumberFlow(FlowTask)
    } catch (error) {
      console.error("Error fetching department names:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Task Modal
  const handleCloseTask = () => setShowTaskModal(false);
  const handleShowTask = () => setShowTaskModal(true);

  // Handle Edit Task Modal
  const handleEditTask = (task) => {
    console.log(task, "task");
    setSelectedTask(task);
    GetTaskDetail(task);

    setShowEditTaskModal(true);
  };

  const GetTaskDetail = async (prcs_di) => {
    try {
      const response = await URL.get(`/api/task/${prcs_di}`);
      const taskDetails = response.data.data;
      console.log(taskDetails);
      seteditFloOrder(taskDetails.task_flow_order);
      seteditactFloOrder(taskDetails.task_flow_order);
      seteditactprcsID(taskDetails.task_flow_order);
      seteditTaskName(taskDetails.task_name);
      seteditTaskDesc(taskDetails.task_description);
      seteditDepID(taskDetails.dept_id);
      seteditOrgID(taskDetails.org_id);
      seteditDeptRoleID(taskDetails.dept_role_id);
      seteditSLA(taskDetails.sla_resolution_time);
      seteditDocum(taskDetails.is_doc_upload_required);
      seteditstatus(taskDetails.status);
      seteditDueDate(taskDetails.due_date);
      seteditPrcsID(taskDetails.process_id);
    } catch (error) {
      console.error("Error fetching process data:", error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: "Unable to fetch process data.",
      //   position: "top-right",
      //   toast: true,
      //   timer: 3000,
      //   background: "#dc3545",
      //   color: "#fff",
      //   timerProgressBar: true,
      //   showConfirmButton: false,
      // });
    }
  };

  const handleEditCloseTask = () => {
    setShowEditTaskModal(false);
    setSelectedTask(null);
  };

  const SettingTaskOrderHandler = (prcs__id) => {
    // console.log(prcs__id, "prcs__id");
    // console.log(editactId, "prcs__id2");
    URL.get("/api/allprocess_task")
      .then((res) => {
        console.log(res.data.data, "ddddkkklll");
        setAllPrcswithTask(res.data.data);

        // Find the organization directly from the response data
        const TaskernAtion = res.data.data.find((prs) => prs.id == prcs__id);
        console.log(TaskernAtion, "getobj");
        if (TaskernAtion && TaskernAtion.id === editactprcsID) {
          // Example action
          // setSomeState(TaskernAtion); // Uncomment if you want to set a state with the TaskernAtion
          console.log("TaskernAtion matches selected ID:", TaskernAtion);

          seteditFloOrder(parseInt(editactFloOrder));
        } else {
          console.log("No match found or TaskernAtion is undefined.");
          if (TaskernAtion?.tasks?.length === 0) {
            seteditFloOrder(1); // Return 1 if the array is empty
          } else {
            const lastObject =
              TaskernAtion?.tasks[TaskernAtion?.tasks?.length - 1];
            const gettheFlowOrder = lastObject.task_flow_order; // Return the value of number_flow
            const AddCountFlowOrder = gettheFlowOrder + 1;

            // console.log(AddCountFlowOrder,"getorder");
            seteditFloOrder(parseInt(AddCountFlowOrder));
          }
        }
      })
      .catch((err) => console.log(err));
  };

  //task
  useEffect(() => {
    const fetchProcessesAndDepartments = async () => {
      setLoading(true);
      try {
        const processResponse = await URL.get("api/allprocess");
        if (processResponse.data && processResponse.data.status) {
          const processData = processResponse.data.data;
          const formattedProcessOptions = processData.map((process, index) => ({
            value: process.id,
            label: process.process_name,
            key: `${process.process_name}-${index}`,
          }));
          setProcessOptions(formattedProcessOptions);
        }

        const departmentResponse = await URL.get(
          "user-management/api/v1/orgdept"
        );
        if (departmentResponse.data && departmentResponse.data.status) {
          const departmentData = departmentResponse.data.data;
          const formattedDepartments = departmentData.map((dept) => ({
            value: dept.id,
            label: dept.department_name,
            roles: dept.organization_role_department.map((role) => ({
              value: role.id,
              label: role.dep_role_name,
            })),
            key: dept.id,
          }));
          setDepartmentOptions(formattedDepartments);
        }
      } catch (error) {
        console.error("Error fetching processes or departments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProcessesAndDepartments();
  }, []);

  const GetTaskFlowing = (d) => {
    const selectedProcess = processOptions.find(
      (process) => process.value === parseInt(d)
    );
    if (selectedProcess) {
      setTasksField(selectedProcess.tasks || []);
      const lastTask =
        selectedProcess.tasks?.[selectedProcess.tasks.length - 1];
      const newTaskFlowOrder = lastTask ? lastTask.task_flow_order + 1 : 1;
      setTaskNumberFlow(newTaskFlowOrder);
    } else {
      setTasksField([]);
      setTaskNumberFlow(1);
    }
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (name === "process_id") {
      setProcessGetvalue(value);
      GetTaskFlowing(parseInt(value));
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "dept_id") {
      const selectedDepartmentId = parseInt(value, 10);
      const selectedDepartment = departmentOptions.find(
        (dept) => dept.value === selectedDepartmentId
      );

      if (selectedDepartment) {
        setRoleOptions(selectedDepartment.roles);
        const newRoleId =
          selectedDepartment.roles.length > 0
            ? selectedDepartment.roles[0].id
            : null;
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: newRoleId,
        }));
      } else {
        setRoleOptions([]);
        setFormData((prevData) => ({
          ...prevData,
          dept_role_id: null,
        }));
      }
    }
  };

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await URL.get("api/allorg");
      const organizationData = response.data.data;
      setOrganizations(organizationData);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  }, []);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  // Handle form submission
  const handleSubmit = async (e) => {
    const payload = {
      task_flow_order: editFloOrder,
      task_name: editTaskName,
      task_description: editDocum,
      org_id: editDepID,
      dept_id: editDepID,
      dept_role_id: editDeptRoleID,
      sla_resolution_time: editSLA,
      is_doc_upload_required: true,
      status: true,
      process_id: editPrcsID,
    };

    try {
      const response = await URL.put(`/api/task/${selectedTask}`, payload, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Task Updated",
          text: response.data.message || "Task updated successfully.",
          position: "top-right",
          toast: true,
          timer: 3000,
          background: "#28a745",
          color: "#fff",
          timerProgressBar: true,
          showConfirmButton: false,
        });

        handleEditCloseTask();
        fetchTasks();
      }
    } catch (error) {
      console.error("Error updating task:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unable to update task.",
        position: "top-right",
        toast: true,
        timer: 3000,
        background: "#dc3545",
        color: "#fff",
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartmentId = parseInt(e.target.value, 10);
    seteditDepID(selectedDepartmentId); // Update the selected department ID

    const selectedDepartment = departmentOptions.find(
      (dept) => dept.value === selectedDepartmentId
    );

    if (selectedDepartment) {
      setRoleOptions(selectedDepartment.roles);
      const newRoleId =
        selectedDepartment.roles.length > 0
          ? selectedDepartment.roles[0].id
          : null;
      seteditDeptRoleID(newRoleId); // Update the selected role ID
    } else {
      setRoleOptions([]);
      seteditDeptRoleID(null); // Reset the role ID if no department is selected
    }
  };

  useEffect(() => {
    const selectedDepartment = departmentOptions.find(
      (dept) => dept.value === editDepID
    );
    if (selectedDepartment) {
      setRoleOptions(selectedDepartment.roles);
      const newRoleId =
        selectedDepartment.roles.length > 0
          ? selectedDepartment.roles[0].id
          : null;
      seteditDeptRoleID(editDeptRoleID); // Update the selected role ID
    }
  }, [editDepID]);

  return (
    <div className="task-page-container ">
      {/* Create Task Button */}
      <div className="d-flex justify-content-end mb-3">
        <Button
          onClick={handleShowTask}
          style={{
            background:
              "linear-gradient(106.37deg, #fca61f 29.63%, #ff8c00 51.55%, #e07b39 90.85%)",
            border: "none",
            marginRight: "10px",
          }}>
          + Task
        </Button>
      </div>

      {/* Task Table */}

      <div className="process-table container mt-5">
        <h3 className="mb-4">Tasks</h3>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Organization Name</th>
              <th>Process Name</th>
              <th>Department</th>
              <th>Department Role</th>
              <th>Task Name</th>
              <th>SLA</th>
              <th>Task Flow Order</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Tasks && Tasks.length > 0 ? (
              Tasks.map((tsk, index) => (
                <tr key={index}>
                  <td>{tsk.organization?.org_name}</td>
                  <td>{tsk.process?.process_name}</td>
                  <td>{tsk.department?.department_name}</td>
                  <td>{tsk.department_role?.dep_role_name}</td>
                  <td>{tsk.task_name}</td>
                  <td>{tsk.sla_resolution_time}</td>
                  <td>{tsk.task_flow_order}</td>
                  <td>
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${tsk.id}`}
                      // label={process.status ? "Active" : "Inactive"}
                      checked={tsk.status}
                      onChange={(e) => handleToggleChange(e, tsk)}
                    />
                  </td>

                  <td>
                    <FaPen
                      style={{
                        cursor: "pointer",
                        color: "green",
                        marginRight: "15px",
                      }}
                      onClick={() => handleEditTask(tsk.id)} // Updated to handle edit
                    />
                    {/* <FaTrash
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => handleDeleteTaskClick(tsk)}
                      /> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No Tasks found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center mt-4">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      {/* Task Modal */}
      <Modal show={showTaskModal} onHide={handleCloseTask} centered size="lg">
        <Modal.Body>
          <AddTask FlowTask={FlowTask} handleClose={handleCloseTask} />
        </Modal.Body>
      </Modal>

      {/* Edit Task Modal */}
      <Modal
        show={showEditTaskModal}
        onHide={handleEditCloseTask}
        centered
        size="lg">
        <Modal.Body>
          {/* <EditTask
            FlowTask={FlowTask}
            taskDataId={selectedTask}
            handleClose={handleEditCloseTask}
          /> */}
          <Container className="task-form-container">
            <h2 className="text-center mb-4">Task</h2>
            <Form>
              <Row>
                {/* Organization Name Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="org_id">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control
                      as="select"
                      name="org_id"
                      value={editOrgID}
                      onChange={(e) => seteditOrgID(e.target.value)}
                      required>
                      <option value="" style={{ color: "#525658" }}>
                        Select Organization
                      </option>
                      {organizations.map((org) => (
                        <option key={org.id} value={org.id}>
                          {org.org_name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* Process Name Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="process_id">
                    <Form.Label>Process Name</Form.Label>
                    <Form.Control
                      as="select"
                      name="process_id"
                      value={editPrcsID}
                      onChange={(e) => {
                        const ds2 = e.target.value;
                        SettingTaskOrderHandler(ds2);
                        seteditPrcsID(ds2);
                      }}
                      required
                      disabled={loading}>
                      <option value="">Select Process</option>
                      {processOptions.map((process) => (
                        <option key={process.key} value={process.value}>
                          {process.label}
                        </option>
                      ))}
                    </Form.Control>
                    {loading && <small>Loading processes...</small>}
                  </Form.Group>
                </Col>
                {/* Department Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="dept_id">
                    <Form.Label>Department</Form.Label>
                    <Form.Control
                      as="select"
                      name="dept_id"
                      value={editDepID}
                      onChange={handleDepartmentChange}
                      required>
                      <option value="">Select Department</option>
                      {departmentOptions.map((dept) => (
                        <option key={dept.key} value={dept.value}>
                          {dept.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* Department Role Dropdown */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="dept_role_id">
                    <Form.Label>Department Role</Form.Label>
                    <Form.Control
                      as="select"
                      name="dept_role_id"
                      value={editDeptRoleID}
                      onChange={(e) => seteditDeptRoleID(e.target.value)} // Correctly handle role selection
                      required
                      disabled={roleOptions.length === 0}>
                      <option value="">Select Department Role</option>
                      {roleOptions.map((role, index) => (
                        <option
                          key={`${role.value}-${index}`}
                          value={role.value}>
                          {role.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {/* Task Name */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="task_name">
                    <Form.Label>Task Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="task_name"
                      value={editTaskName}
                      placeholder="Task Name"
                      onChange={(e) => seteditTaskName(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                {/* SLA Resolution Time */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="sla_resolution_time">
                    <Form.Label>SLA Resolution Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="sla_resolution_time"
                      value={editSLA}
                      onChange={(e) => {
                        console.log("SLA Resolution Time:", e.target.value); // Check the value being set
                        seteditSLA(e.target.value);
                      }}
                      required
                    />
                  </Form.Group>
                </Col>
                {/* Task Flow Order */}
                <Col md={6} style={{ padding: "10px" }}>
                  <Form.Group controlId="task_flow_order">
                    <Form.Label>Task Flow Order</Form.Label>
                    <Form.Control
                      disabled
                      type="number"
                      name="task_flow_order"
                      value={editFloOrder}
                      onChange={(e) => seteditFloOrder(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                {/* Is Document Required Checkbox */}
                <Col md={6} style={{ padding: "50px 10px" }}>
                  <Form.Group controlId="is_doc_upload_required">
                    <Form.Check
                      type="checkbox"
                      label="Is Document Required"
                      name="is_doc_upload_required"
                      checked={editDocum}
                      onChange={(e) => seteditDocum(e.target.value)}
                    />{" "}
                  </Form.Group>{" "}
                </Col>{" "}
                {/* Task Description */}{" "}
                <Col md={12} style={{ padding: "10px" }}>
                  {" "}
                  <Form.Group controlId="task_description">
                    {" "}
                    <Form.Label>Task Description</Form.Label>{" "}
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="task_description"
                      value={editTaskDesc}
                      onChange={(e) => seteditTaskDesc(e.target.value)}
                      required
                    />{" "}
                  </Form.Group>{" "}
                </Col>{" "}
                <div className="form-actions mb-4">
                  <Button
                    variant="outline-light"
                    type="reset"
                    className="cancel-btn"
                    onClick={handleEditCloseTask}>
                    Cancel Task
                  </Button>
                  <Button
                    variant="outline-light"
                    // type="submit"
                    className="create-btn"
                    onClick={(e) => handleSubmit(e)}>
                    Update Task
                  </Button>
                </div>
              </Row>{" "}
            </Form>{" "}
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Task;
