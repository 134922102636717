import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

 
// Replace this function with your actual login API call
export const loginUser = createAsyncThunk('auth/login', async ({ email, password ,role}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user-management/api/v1/sign-in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password,role }),
    });
  
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to log in');
    }
  
    const data = await response.json();
    localStorage.setItem('user', JSON.stringify(data)); // Adjust as needed based on your API response structure



    return data; // Adjust based on your API response structure
  });


  //logout 
  export const logoutUser = createAsyncThunk('auth/logout', async () => {
    // This can remain as a no-op since we're just removing local storage
    localStorage.removeItem('user');
    return {}; // Return an empty object
  });

  const getUserFromLocalStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  };

  
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: getUserFromLocalStorage(), // Initialize state from localStorage
    status: 'idle',
    error: null,
  },
  reducers: {
    logout(state) {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        state.error = null; // Reset error on successful login
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = 'idle'; // You can set this to whatever is appropriate
        state.user = null; // Clear user data
        state.error = null; // Reset error on logout
      });
  }
});

export const { logout } = authSlice.actions;

export const selectIsAuthenticated = (state) => {
    const user = state.auth.user;
    return user && user.status === true && user.data.token && user.data.user_code;
  };
  

export default authSlice.reducer;
