import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Pagination, Form } from "react-bootstrap";
import AddOrganization from "../components/FormComponents/OrganizationForm/AddOrganization";
import EditOrganization from "../components/FormComponents/OrganizationForm/EditOrganization";
import URL from "../API/Api";
import { FaPen, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2"; // Import SweetAlert2
import "./Pages.css"; // Import the CSS file

const Organization = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(5);
  const [loading, setLoading] = useState(false);

  // Toggle Modals
  const toggleAddModal = () => setShowAddModal((prev) => !prev);
  const toggleEditModal = () => setShowEditModal((prev) => !prev);

  // Fetch organizations from API
  const fetchOrganizations = async (page = 1) => {
    try {
      setLoading(true);
      const response = await URL.get(`api/org`, { params: { limit, page } });
      const data = response.data.data;
      setOrganizations(data.data);
      setCurrentPage(data.current_page);
      setTotalPages(data.last_page);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganizations(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Edit Button Click
  const handleEditButtonClick = (organization) => {
    setSelectedOrganization(organization);
    toggleEditModal();
  };

  // Handle Delete Button Click
  const handleDeleteButtonClick = (organization) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await URL.delete(`api/org/${organization.id}`);
          Swal.fire({
            title: "Deleted!",
            text: "Your organization has been deleted.",
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
          fetchOrganizations(currentPage);
        } catch (error) {
          console.error("Error deleting organization:", error);
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting the organization.",
            icon: "error",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      }
    });
  };
  // Handle Toggle Switch Change
  const handleToggleChange = async (e, organization) => {
    try {
      let status = e.target.checked ? 1 : 0;

      let data = {
        status: status,
      };

      console.log(data, "organizationhhh");
      await URL.put(`api/org/${organization.id}`, data);

      fetchOrganizations(currentPage);
    } catch (error) {
      console.error("Error updating organization status:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error updating the organization status.",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };
  // Handle form submission and close modals
  const handleAddOrganizationClose = () => {
    setShowAddModal(false);
    fetchOrganizations(currentPage);
  };

  const handleEditOrganizationClose = () => {
    setShowEditModal(false);
    setSelectedOrganization(null);
    fetchOrganizations(currentPage);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={toggleAddModal}
          style={{
            background:
              "linear-gradient(106.37deg, #fca61f 29.63%, #ff8c00 51.55%, #e07b39 90.85%)",
            border: "none",
            padding: "10px 15px",
            margin: "5px",
            transition: "background 0.3s ease",
            fontSize: "16px",
            cursor: "pointer",
          }}>
          + Organization
        </Button>
        {/* Add Organization Modal */}
        <Modal show={showAddModal} onHide={toggleAddModal} centered size="lg">
          <Modal.Body>
            <AddOrganization handleClose={handleAddOrganizationClose} />
          </Modal.Body>
        </Modal>
      </div>

      <div className="organization-table">
        <h3 className="text-center mb-5">Organization</h3>
        <Table
          striped
          bordered
          hover
          responsive
          className="organization-table text-center">
          <thead>
            <tr>
              <th>#</th> {/* Index Column */}
              <th>Organization Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {organizations.length > 0 ? (
              organizations.map((organization, index) => (
                <tr key={organization.id}>
                  <td>{(currentPage - 1) * limit + index + 1}</td>{" "}
                  {/* Display Index */}
                  <td>{organization.org_name}</td>
                  <td>
                    {" "}
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${organization.id}`}
                      //  label={organization.status ? "Active" : "Inactive"}
                      checked={organization.status}
                      onChange={(e) => handleToggleChange(e, organization)}
                      style={{ marginLeft: "15px" }}
                    />
                  </td>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <Button
                      onClick={() => handleEditButtonClick(organization)}
                      className="edit-button"
                      style={{
                        cursor: "pointer",
                        color: "green",
                        marginRight: "15px",
                      }}
                      variant="link">
                      <FaPen style={{ fontSize: "25px" }} />
                    </Button>

                    {/* Commented out the delete button */}
                    {/* <Button
    onClick={() => handleDeleteButtonClick(organization)}
    className="delete-button"
    style={{ cursor: "pointer", color: "red" }}
    variant="link">
    <FaTrash style={{ fontSize: "25px" }} />
  </Button> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  {" "}
                  {/* Adjusted to span 3 columns */}
                  No organizations found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-center mt-4">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      {/* Edit Organization Modal */}
      <Modal show={showEditModal} onHide={toggleEditModal} centered size="lg">
        <Modal.Body>
          {selectedOrganization && (
            <EditOrganization
              handleClose={handleEditOrganizationClose}
              organizationId={selectedOrganization.id}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Organization;
