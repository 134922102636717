import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Tooltip } from "react-bootstrap";
import API from "../../API/ServiceLayer";
import './MasterOrg.css';
import Swal from "sweetalert2";
import BootstrapToast from "../../components/BootstrapToast";
import { BsPencil, BsTrash } from "react-icons/bs";
import Select from "../../components/FormComponent/Select";
  
  export default function DepartmentTree() { 
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalOpen, setEditModelOpen] = useState(false);
  
    const [OrganizationDepName, setOrganizationDepName] = useState();
    const [OrganizationDepRole, setOrganizationDepRole] = useState();
    const [listOrganizationDeps, setListOrganizationDeps] = useState([]);
    const [filteredResult, setFilteredData] = useState([]);
    const [listOrganizationDepsRoles, setListOrganizationDepsRoles] = useState([]);
  
    const [status, setStatus] = useState(1);
    const [editId, setEditId] = useState();
    //for update
    const [updatedName, setUpdatedName] = useState("");
    const [updatRole, setUpdateRole] = useState("");
  
    const [updateStatus, setUpdateStatus] = useState();
    ////////////Department states//////////////////
  
    const [modalDepIsOpen, setModalDepIsOpen] = useState(false);
    const [editDepModalOpen, setEditDepModelOpen] = useState(false);
    const [updatedDepEdit, setUpdatedDepEdit] = useState();
      const [OrganizationDepartmentName, setOrganizationDepartmentName] =useState();
      const [OrganizationDepartmentDesc, setOrganizationDepartmentDesc] =useState();
      const [Depstatus, setDepStatus] = useState(1);
      const [DepeditId, setDepEditId] = useState();
    //for update
    const [updatedDepName, setUpdatedDepName] = useState();
    const [updatedDepDesc, setUpdatedDepDesc] = useState();
  
    const [updateDepStatus, setUpdateDepStatus] = useState();
    const [snackOpen, setSnackOpen] = useState(false);
    const [toastObject, setToastObject] = useState({
      message: "",
      duration: 3000,
      variant: "",
    });
    const OrganizationDep = listOrganizationDeps.map((i) => {
      return {
        id: i.id,
        name: i.department_name,
        code: i.Organization_department_code,
      };
    });
    ///Dep roles////////////////
    useEffect(() => {
      if (editId) {
        setModdalEdit(editId);
      }
    }, [editId]);
    const handleChange = (e) => {
      let index = e.target.selectedIndex;
      let selectedOption = {
        id: e.target.options[index].value,
        name: e.target.options[index].text,
      };
      if (e.target.name === "updateddepName") {
        setUpdatedName(selectedOption.id);
      } else if (e.target.name === "depName") {
        setOrganizationDepName(selectedOption);
      }
    };
    const changeStatusUpdate = (data) => {
      // console.log("run", data, typeof data);
      if (data == 1) {
        // console.log(data);
        setUpdateStatus(0);
      } else if (data == 0) {
        // console.log(data);
        setUpdateStatus(1);
      }
    };
    const setModalIsOpenToTrue = () => {
      setModalIsOpen(true);
    };
  
    const setModalIsOpenToFalse = () => {
      setModalIsOpen(false);
    };
    const setEditModalIsOpenToTrue = () => {
      setEditModelOpen(true);
    };
    const setEditModalIsOpenToFalse = () => {
      setEditModelOpen(false);
    };
    const setModdalEdit = (id) => {
      API.getData(`user-management/api/v1/orgdept-role/${id}`)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log("result", result);
          localStorage.setItem("message", result?.message);
  
          setUpdatedName(result?.data?.organization_department_id);
          setUpdateRole(result?.data?.dep_role_name);
  
          setUpdateStatus(result?.data.dep_role_status);
  
          // setUpdatedEdit(result.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleDelete = (id) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "All data in the row will be deleted, do you wish to continue?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let data = {
              status: 0,
            };
            API.postMethod("user-management/api/v1/orgdept-role/" + id, "delete", data)
              .then((res) => res.json())
              .then((result) => {
                // console.log(result);
                localStorage.setItem("message", result?.message);
                // setSnackOpen(true);
                // setToastObject({
                //   message: result.message,
                //   duration: 3000,
                //   variant: 'success',
                // });
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: result.message,
                  position: "top-right",
                  toast: true,
                  timer: 3000,
                  background: "#28a745",
                  color: "#fff",
                  timerProgressBar: true,
                  showConfirmButton: false,
                });
      
                // Fetch updated data
                API.getData(`user-management/api/v1/orgdept`)
                  .then((res) => res.json())
                  .then((result) => {
                    // console.log("resswsw", result);
                    if (result.status) {
                      setListOrganizationDeps(
                        result.data.filter((stat) => stat.status !== 2)
                      );
                      setFilteredData(
                        result.data
                          .filter((j) => j !== null)
                          .map((i) => {
                            if (i && i.organization_role_department.length > 0) {
                              return { ...i };
                            }
                            return null;
                          })
                          .filter((k) => k !== null)
                      );
                    }
                  });
              })
              .catch((err) => {
                console.log("error", err);
              });
          }
        });
      };
    const handleStatusUpdate = (e, id) => {
      let dep_role_status = "";
      if (e.target.checked === true) {
        dep_role_status = 1;
      } else {
        dep_role_status = 0;
      }
      let data = {
        dep_role_status: dep_role_status,
      };
  
      API.postMethod("user-management/api/v1/orgdept-role/" + id, "put", data)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          localStorage.setItem("message", result?.message);
          // setSnackOpen(true);
          // setToastObject({
          //   message: result.message,
          //   duration: 3000,
          //   variant: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "#28a745",
            color: "#fff",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          API.getData(`user-management/api/v1/orgdept`)
            .then((res) => res.json())
            .then((result) => {
              // console.log("resswsw", result);
              if (result.status) {
                setListOrganizationDeps(
                  result.data.filter((stat) => {
                    if (stat.status !== 2) {
                      return stat;
                    }
                  })
                );
                setFilteredData(
                  result.data
                    .filter((j) => j !== null)
                    .map((i) => {
                      if (i && i.organization_role_department.length > 0) {
                        return { ...i };
                      }
                      return null;
                    })
                    .filter((k) => k !== null)
                );
              }
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleFormSubmit = (event) => {
      event.preventDefault();
      // console.log("clicked");
  
      let data = {
        organization_department_id: Number(OrganizationDepName.id),
        dep_role_name: OrganizationDepRole,
        dep_role_status: status,
        //  dep_role_code: "MGSUP001" + Math.random(),
      };
  
      API.postMethod("user-management/api/v1/orgdept-role", "post", data)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          localStorage.setItem("message", result?.message);
          // setSnackOpen(true);
          // setToastObject({
          //   message: result.message,
          //   duration: 3000,
          //   variant: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "#28a745",
            color: "#fff",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          API.getData(`user-management/api/v1/orgdept`)
            .then((res) => res.json())
            .then((result) => {
              // console.log("resswsw", result);
              if (result.status) {
                setListOrganizationDeps(
                  result.data.filter((stat) => {
                    if (stat.status !== 2) {
                      return stat;
                    }
                  })
                );
                setFilteredData(
                  result.data
                    .filter((j) => j !== null)
                    .map((i) => {
                      if (i && i.organization_role_department.length > 0) {
                        return { ...i };
                      }
                      return null;
                    })
                    .filter((k) => k !== null)
                );
              }
              setModalIsOpenToFalse();
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleEditFormSubmit = (e) => {
      e.preventDefault();
      let data = {
        organization_department_id: Number(updatedName),
        dep_role_name: updatRole,
        dep_role_status: updateStatus,
      };
      // console.log(editId);
  
      API.postMethod("user-management/api/v1/orgdept-role/" + editId, "put", data)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          localStorage.setItem("message", result?.message);
          // setSnackOpen(true);
          // setToastObject({
          //   message: result.message,
          //   duration: 3000,
          //   variant: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "#28a745",
            color: "#fff",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          API.getData(`user-management/api/v1/orgdept`)
            .then((res) => res.json())
            .then((result) => {
              // console.log("resswsw", result);
              if (result.status) {
                setListOrganizationDeps(
                  result.data.filter((stat) => {
                    if (stat.status !== 2) {
                      return stat;
                    }
                  })
                );
                setFilteredData(
                  result.data
                    .filter((j) => j !== null)
                    .map((i) => {
                      if (i && i.organization_role_department.length > 0) {
                        return { ...i };
                      }
                      return null;
                    })
                    .filter((k) => k !== null)
                );
              }
  
              setEditModalIsOpenToFalse();
            });
          // window.location.reload();
        })
  
        .catch((err) => {
          console.log("error", err);
        });
    };
    //////////////////////Department crud////////////////////
    useEffect(() => {
      if (DepeditId) {
        setModalDepEdit(DepeditId);
      }
    }, [DepeditId]);
    const setModalDepIsOpenToTrue = () => {
      setModalDepIsOpen(true);
    };
  
    const setModalDepIsOpenToFalse = () => {
      setModalDepIsOpen(false);
    };
    const setEditDepModalIsOpenToTrue = () => {
      setEditDepModelOpen(true);
    };
    const setEditDepModalIsOpenToFalse = () => {
      setEditDepModelOpen(false);
    };
    const setModalDepEdit = (id) => {
      API.getData(`user-management/api/v1/orgdept/${id}`)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          localStorage.setItem("message", result?.message);
          setUpdatedDepName(result?.data?.department_name);
          setUpdatedDepDesc(result?.data?.description);
          setUpdateDepStatus(result?.data.status);
  
          // setUpdatedEdit(result.data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleDepFormSubmit = (event) => {
      event.preventDefault();
  
      let data = {
        department_name: OrganizationDepartmentName,
        // dep_role_name: OrganizationDepartmentRole,
        description: OrganizationDepartmentDesc,
        status: Depstatus,
      };
      // console.log("clicked", data);
      API.postMethod("user-management/api/v1/orgdept", "post", data)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          localStorage.setItem("message", result?.message);
          // setSnackOpen(true);
          // setToastObject({
          //   message: result.message,
          //   duration: 3000,
          //   variant: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "#28a745",
            color: "#fff",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          API.getData(`user-management/api/v1/orgdept`)
            .then((res) => res.json())
            .then((result) => {
              // console.log("resswsw", result);
              if (result.status) {
                setListOrganizationDeps(
                  result.data.filter((stat) => {
                    if (stat.status !== 2) {
                      return stat;
                    }
                  })
                );
                setFilteredData(
                  result.data
                    .filter((j) => j !== null)
                    .map((i) => {
                      if (i && i.organization_role_department.length > 0) {
                        return { ...i };
                      }
                      return null;
                    })
                    .filter((k) => k !== null)
                );
              }
              setModalDepIsOpenToFalse();
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleEditDepFormSubmit = (e) => {
      e.preventDefault();
      let data = {
        department_name: updatedDepName,
        description: updatedDepDesc,
        status: updateDepStatus,
      };
      // console.log(editId);
  
      API.postMethod("user-management/api/v1/orgdept/" + DepeditId, "put", data)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          localStorage.setItem("message", result?.message);
          // setSnackOpen(true);
          // setToastObject({
          //   message: result.message,
          //   duration: 3000,
          //   variant: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "#28a745",
            color: "#fff",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          API.getData(`user-management/api/v1/orgdept`)
            .then((res) => res.json())
            .then((result) => {
              // console.log("resswsw", result);
              if (result.status) {
                setListOrganizationDeps(
                  result.data.filter((stat) => {
                    if (stat.status !== 2) {
                      return stat;
                    }
                  })
                );
                setFilteredData(
                  result.data
                    .filter((j) => j !== null)
                    .map((i) => {
                      if (i && i.organization_role_department.length > 0) {
                        return { ...i };
                      }
                      return null;
                    })
                    .filter((k) => k !== null)
                );
              }
              setEditDepModalIsOpenToFalse();
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const changeStatusDepUpdate = (data) => {
      // console.log("run", data, typeof data);
      if (data == 1) {
        // console.log(data);
        setUpdateDepStatus(0);
      } else if (data == 0) {
        // console.log(data);
        setUpdateDepStatus(1);
      }
    };
    const handleDepStatusUpdate = (e, id) => {
      let status = "";
      if (e.target.checked === true) {
        status = 1;
      } else {
        status = 0;
      }
      let data = {
        status: status,
      };
  
      API.postMethod("user-management/api/v1/orgdept/" + id, "put", data)
        .then((res) => res.json())
  
        .then((result) => {
          // console.log(result);
          // setSnackOpen(true);
          // setToastObject({
          //   message: result.message,
          //   duration: 3000,
          //   variant: "success",
          // });
          Swal.fire({
            icon: "success",
            title: "Success",
            text: result.message,
            position: "top-right",
            toast: true,
            timer: 3000,
            background: "#28a745",
            color: "#fff",
            timerProgressBar: true,
            showConfirmButton: false,
          });
          localStorage.setItem("message", result?.message);
          API.getData(`user-management/api/v1/orgdept`)
            .then((res) => res.json())
            .then((result) => {
              // console.log("resswsw", result);
              if (result.status) {
                setListOrganizationDeps(
                  result.data.filter((stat) => {
                    if (stat.status !== 2) {
                      return stat;
                    }
                  })
                );
                setFilteredData(
                  result.data
                    .filter((j) => j !== null)
                    .map((i) => {
                      if (i && i.organization_role_department.length > 0) {
                        return { ...i };
                      }
                      return null;
                    })
                    .filter((k) => k !== null)
                );
              }
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
  
    useEffect(() => {
      API.getData(`user-management/api/v1/orgdept`)
        .then((res) => res.json())
        .then((result) => {
          // console.log("resswsw", result);
          if (result.status) {
            setListOrganizationDeps(
              result.data.filter((stat) => {
                if (stat.status !== 2) {
                  return stat;
                }
              })
            );
            setFilteredData(
              result.data
                .filter((j) => j !== null)
                .map((i) => {
                  if (i && i.organization_role_department.length > 0) {
                    return { ...i };
                  }
                  return null;
                })
                .filter((k) => k !== null)
            );
          }
        });
      API.getData(`user-management/api/v1/orgdept-role`)
        .then((res) => res.json())
        .then((result) => {
          // console.log("roleswsw", result);
          if (result.status) {
            setListOrganizationDepsRoles(
              result.data.filter((stat) => {
                if (stat.status !== 2) {
                  return stat;
                }
              })
            );
          }
        });
    }, []);
   
    return (
      <div>
        <Row>
          {/* FLOWCHART */}
          <Col lg={12} md={12} sm={12}>
            {filteredResult ? (
              <>
                <div className="chartContainer">
                  {/* HEADER */}
                  <p className="titleContainer">
                    <span className="module">Department Tree</span>
                    <div>
                      <Button
                        className="notesContainer"
                        variant="contained"
                        onClick={() => setModalDepIsOpenToTrue()}
                      >
                         
                        <p style={{ color: "#ffffff", fontSize: "15px" }}>
                          Add Department
                        </p>
                      </Button>
                      <Button
                        className="notesContainer"
                        variant="contained"
                        onClick={setModalIsOpenToTrue}
                      >
                        <p style={{ color: "#ffffff", fontSize: "15px" }}>
                          Add Department role
                        </p>
                      </Button>
                    </div>
                  </p>
                  <p>{filteredResult.department_name}</p>
                  <div>
                    {/* FUNCTIONLIST */}
  
                    {/* MENULIST */}
  
                    {listOrganizationDeps?.map((menuItem) => {
                      return (
                        <>
                          <div key={menuItem.id} className="menuContainer">
                            <p className="menuPosition">
                              <div key={menuItem.id} className="menu">
                                <span style={{ marginRight: "8px" }}>
                                  {menuItem.department_name}
                                </span>{" "}
                                    <span style={{ cursor:"pointer"}}
                                    onClick={() => {
                                      setEditDepModalIsOpenToTrue(true);
  
                                      setDepEditId(menuItem.id);
                                    }}
                                  >
                                       <BsPencil color="black" />
                                    </span>
                                  {/* </IconButton> */}
                                {/* </Tooltip>  */} 
                                  <Form.Check
                                    name="is_Dep_enable"
                           
                                    onChange={(e) =>
                                      handleDepStatusUpdate(e, menuItem.id)
                                    }
                                    type="switch"
                                    defaultChecked={
                                      menuItem.status === 1 ? true : false
                                    }
                                    checked={menuItem.status === 1}
                                    style={{
                                      marginLeft: "10px",
                                      color: "#fc9200",
                                    }}
                                    id={menuItem.id + Math.random()}
                                    label=""
                                  />  
                              </div>
  
                            </p>
  
                            <div className="subMenuContainer">
                              {menuItem.organization_role_department?.map(
                                (subMenu) => {
                                  // console.log(subMenu, "submenu");
                                  return (
                                    <>
                                      <>
                                        <div
                                          key={subMenu.id}
                                          className="subMenu"
                                        >
                                          <span
                                            style={{
                                              marginRight: "8px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {subMenu.dep_role_name}
                                          </span>{" "} 
                                            <span
                                              onClick={() => {
                                                setEditModalIsOpenToTrue(true);
  
                                                setEditId(subMenu.id);
                                              }}
                                            >
                                                  <i className="bi bi-pencil"></i>
                                                  <BsPencil color="black" />
                                            </span> 
                                            <span 
                                             
                                              onClick={(e) => {
                                                handleDelete(e, subMenu.id);
                                              }}
                                            >
                                             <BsTrash color="black" />
                                            </span> 
                                            <Form.Check
                                              name="is_enable"
                             
                                              onChange={(e) =>
                                                handleStatusUpdate(e, subMenu.id)
                                              }
                                              type="switch"
                                              defaultChecked={
                                                subMenu.dep_role_status === 1
                                              }
                                              checked={
                                                subMenu.dep_role_status === 1
                                              }
                                              style={{
                                                marginLeft: "10px",
                                                color: "#fc9200",
                                              }}
                                              id={subMenu.id + Math.random()}
                                              label=""
                                            />
                                        </div>
                                      </>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <div sx={{ textAlign: "center" }}>
                <p >
                  Let's start by Enter Module Name
                </p>
              </div>
            )}
          </Col>
        </Row>
        <BootstrapToast
  duration={toastObject.duration}
  onSetOpen={setSnackOpen}
  open={snackOpen}
  variant={toastObject.variant} // success, danger, warning, etc.
/>
        <Modal
          backdrop="static"
          show={modalIsOpen}
          onHide={setModalIsOpenToFalse}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Add Organization Department Role
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <form onSubmit={handleFormSubmit}>
              <Form.Group>
                <label>
                  Organization Department Role{" "}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  //  pattern="\s*\S+.*[A-Za-z][A-Za-z\s ]*$"
                  required
                  maxLength={55}
                  name="dep_role"
                  // value={this.state.dep_name}
                  // onChange={(e) => this.handleChange(e)}
                  placeholder="Enter Organization Department Role"
                  onChange={(e) => setOrganizationDepRole(e.target.value)}
                  size="lg"
                />
              </Form.Group>
  
              
            <Select
              label="Organization Department Name"
              options={OrganizationDep}
              placeholder="Select Organization Department"
              onChange={(e) => handleChange(e)}
              //value={OrganizationDepName?.id}
              name="depName"
            />
  
              <Form.Group>
                <label>Status</label>
                <Form.Check
                  name="status"
                  value={status}
                  checked={status}
                  onChange={(e) => setStatus(!status)}
                  // defaultChecked={this.state.status == 1 ? true : false}
                  // onChange={(e) => this.handleChange(e)}
                  type="switch"
                  id="amenity-status"
                  label=""
                />
              </Form.Group>
  
              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="reset"
                  onClick={() => setModalIsOpenToFalse({ show: false })}
                  // onClick={() => this.setState({ show: false })}
                  className="btn btn-light rounded-0"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary mr-2 rounded-0">
                  Create
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          show={editModalOpen}
          onHide={setEditModalIsOpenToFalse}
          //  mansoor={updatedEdit}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Edit Organization Department Role
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <form onSubmit={handleEditFormSubmit}>
              <Form.Group>
                <label>
                  Organization Department Role{" "}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  value={updatRole}
                  onChange={(e) => setUpdateRole(e.target.value)}
                  placeholder="Enter Department Role"
                  size="lg"
                />
              </Form.Group>
              <Form.Group controlId="organizationDepSelect">
      <Form.Label>Organization Department Name</Form.Label>
      <Select
              label="Organization Department Name"
              options={OrganizationDep}
              placeholder="Select Organization Department"
              onChange={(e) => handleChange(e)}
              value={updatedName}
              name="updateddepName"
            />
    </Form.Group>
              <Form.Group>
                <label>Status </label>
                <Form.Check
                  name="status"
                  type="switch"
                  id="amenity-status"
                  label=""
                  checked={updateStatus == 1 ? true : false}
                  // onChange={setUpdateStatus(0)}
                  onChange={() => {
                    changeStatusUpdate(updateStatus);
                  }}
                />
              </Form.Group>
  
              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="reset"
                  className="btn btn-light rounded-0"
                  onClick={() => setEditModalIsOpenToFalse({ show: false })}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary mr-2 rounded-0">
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* Department models */}
        <Modal
          backdrop="static"
          show={modalDepIsOpen}
          onHide={setModalDepIsOpenToFalse}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Add Organization Department
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <form onSubmit={handleDepFormSubmit}>
              <Form.Group>
                <label>
                  Organization Department Name{" "}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  pattern="\s*\S+.*[A-Za-z][A-Za-z\s ]*$"
                  required
                  maxLength={55}
                  name="dep_name"
                  placeholder="Enter Organization Department Name"
                  onChange={(e) => setOrganizationDepartmentName(e.target.value)}
                  size="lg"
                />
              </Form.Group>
              
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  type="text"
                  //   pattern="\s*\S+.*[A-Za-z][A-Za-z\s ]*$"
                  // required
                  maxLength={55}
                  name="dep_desc"
                  // value={this.state.dep_name}
                  //onChange={(e) => this.handleChange(e)}
                  placeholder="Enter Description"
                  onChange={(e) => setOrganizationDepartmentDesc(e.target.value)}
                  size="lg"
                />
              </Form.Group>
  
              <Form.Group>
                <label>Status</label>
                <Form.Check
                  name="status"
                  value={status}
                  checked={status}
                  onChange={(e) => setDepStatus(!status)}
                  // defaultChecked={this.state.status == 1 ? true : false}
                  // onChange={(e) => this.handleChange(e)}
                  type="switch"
                  id="amenity-status"
                  label=""
                />
              </Form.Group>
  
              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="reset"
                  onClick={() => setModalDepIsOpenToFalse()}
                  className="btn btn-light rounded-0"   >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary mr-2 rounded-0">
                  Create
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          show={editDepModalOpen}
          onHide={setEditDepModalIsOpenToFalse}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              EDIT Organization Department
            </Modal.Title>
          </Modal.Header>
  
          <Modal.Body>
            <form onSubmit={handleEditDepFormSubmit}>
              <Form.Group>
                <label>
                  Organization Department Name{" "}
                  <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="text"
                  // defaultValue={updatedEdit?.department_name}
                  value={updatedDepName}
                  onChange={(e) => setUpdatedDepName(e.target.value)}
                  placeholder="Enter Department Name"
                  size="lg"
                />
              </Form.Group>
              <Form.Group>
                <label>Description</label>
                <Form.Control
                  type="text"
                  // defaultValue={updatedEdit?.department_name}
                  value={updatedDepDesc}
                  onChange={(e) => setUpdatedDepDesc(e.target.value)}
                  placeholder="Enter Description"
                  size="lg"
                />
              </Form.Group>
  
              <Form.Group>
                <label>Status </label>
                <Form.Check
                  name="status"
                  type="switch"
                  id="amenity-status"
                  label=""
                  checked={updateDepStatus == 1 ? true : false}
                  // onChange={setUpdateStatus(0)}
                  onChange={() => {
                    changeStatusDepUpdate(updateDepStatus);
                  }}
                />
              </Form.Group>
  
              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="reset"
                  className="btn btn-light rounded-0"
                  onClick={() => setEditDepModalIsOpenToFalse({ show: false })}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary mr-2 rounded-0">
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
  